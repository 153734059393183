<template>
  <div class="payment-method-type-btn brand-secondary brand-secondary-hw brand-button-border-radius" @click="methodTypeSelected">
    <div class="payment-method-type-btn__label">{{ $t('METHOD_TYPE.' + type) }}</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';

import ServiceMethodType = com.paidora.billing.types.models.enums.ServiceMethodType;

@Component({})
export default class P2PMethodTypeButton extends Vue {
  @Prop()
  type!: ServiceMethodType;
  methodTypeLogos: { [method: string]: { alias: string, extension: string } } = {
    BKASH: {
      alias: 'bkash',
      extension: 'svg'
    },
    CARD: {
      alias: 'card',
      extension: 'svg'
    },
    NAGAD: {
      alias: 'nagad',
      extension: 'svg'
    },
    PK_BANK: {
      alias: 'pk_bank',
      extension: 'svg'
    },
    PK_EASYPAISA: {
      alias: 'pk_easypaisa',
      extension: 'svg'
    },
    PK_JAZZCASH: {
      alias: 'pk_jazzcash',
      extension: 'svg'
    },
    ROCKET: {
      alias: 'rocket',
      extension: 'svg'
    },
    SBERPAY: {
      alias: 'sberpay',
      extension: 'svg'
    },
    SBP: {
      alias: 'sbp',
      extension: 'png'
    },
    UPAY: {
      alias: 'upay',
      extension: 'svg'
    }
  };

  @Emit('method-type-selected')
  methodTypeSelected() {
    return this.methodTypeLogos[this.type].alias;
  }
}
</script>

<style lang="scss">
.payment-method-type-btn {
  height: 60px;
  font-size: 18px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .payment-method-type-btn__label {

  }

  .payment-method-type-btn__logo {
    display: block;
    max-width: 50%;
    max-height: 25px;
    width: auto;
    height: 100%;
  }
}
</style>
