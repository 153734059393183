<template>
  <FormContent>
    <FormField>
      <CardPanInputFormField
        ref="cardPanField"
        :value="formFields.destination"
        :inputName="'destination'"
        @changed="changePan($event)"
      ></CardPanInputFormField>
    </FormField>
    <FormField>
      <ReceiverNameInputFormField
        ref="cardHolderField"
        :value="formFields.receiverName"
        @changed="changeCardHolder($event)"
      ></ReceiverNameInputFormField>
    </FormField>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import CardPanInputFormField from '@/components/form/common/form/fields/CardPanInputFormField.vue';
import CardHolderInputFormField from '@/components/form/common/form/fields/CardHolderInputFormField.vue';
import {FormFieldEvent} from '@/components/form/common/form/fields/InputFormFieldBase';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import ReceiverNameInputFormField from '@/components/form/common/form/fields/ReceiverNameInputFormField.vue';

@Component(
  {
    components: {
      ReceiverNameInputFormField,
      CardHolderInputFormField,
      CardPanInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })
export default class CardMethodForm extends DataFormBase implements IForm {
  showModal = false;
  modalText?: string;
  modalHeader?: string | null;

  constructor() {
    super();
    this.formFields = {
      receiverName: '',
      destination: ''
    };
  }

  changePan(e: FormFieldEvent<string>) {
    this.formFields.cardPan = e.value;
    this.validate();
  }

  changeCardHolder(e: FormFieldEvent<string>) {
    this.formFields.cardHolder = e.value;
    this.validate();
  }
}
</script>

<style lang="scss" scoped>
</style>
