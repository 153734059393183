<template>
  <div class="form-root">
    <spinner v-if="loading"></spinner>
    <div v-if="!loading">
      <div class="main-info brand-iframe">
        <div class="main-info-body no-select brand-secondary brand-header-border-radius-bb brand-header-border-radius-bt">
          <div class="main-info-body-label">
            <div class="payout-label">
              <span class="order-text">{{ $t('PAYOUT_FORM.ENTER_DETAILS') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="payouts-destination-header no-select"></div>
      <div>
        <div class="panel-body">
          <form ref="payoutDataForm" @submit.prevent="submitPayoutData()">
            <component v-bind:is="currentFormComponent" v-if="currentFormComponent" ref="methodComponent"
                       v-bind="{formData: formData}" @valid-form="validForm"></component>
            <FormContent>
              <FormRow>
                <FormCol>
                  <div class="payouts-destination-confirmation">
                    <label for="all_correct"
                           v-bind:class="{ invalid: confirmedInvalid }">
                      <input id="all_correct"
                             v-model="confirmed"
                             class="checkbox"
                             type="checkbox"><span class="checkmark"></span>
                      {{ $t('PAYOUT_FORM.CONFIRMATION_CHECKBOX') }}
                    </label>
                  </div>
                </FormCol>
              </FormRow>
            </FormContent>
            <input ref="payoutDataFormSubmit" style="display:none" type="submit">
            <div v-if="error" class="error-label brand-error">
              <span class="c-error-line">{{ $t(error) }}</span>
            </div>
          </form>
          <div class="panel-footer">
            <div>
              <div class="panel-footer-submit no-select">
                <button class="button-pay button-accent brand-button-accent brand-button-border-radius" type="button" v-on:click="submitForm()">
                  <svg v-if="!confirmed || !isValid" height="20" viewBox="0 0 17 20" width="17" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9767 7.82245H14.4736V5.88571C14.4715 2.54286 11.7992 0 8.50106 0C5.20296 0 2.52854 2.54286 2.52854 5.88571V7.82245H2.02537C0.906977 7.82245 0 8.69592 0 9.77143V18.049C0 19.1265 0.906977 20 2.02537 20H14.9746C16.093 20 17 19.1265 17 18.049V9.77347C17 8.69592 16.093 7.82245 14.9767 7.82245ZM5.06765 6.14082C5.06765 4.1551 6.60465 2.54694 8.50106 2.54694C10.3975 2.54694 11.9323 4.15714 11.9323 6.14082V7.88163H5.06765V6.14082ZM9.35307 13.8551V16.0265C9.35307 16.4816 8.97252 16.849 8.50106 16.849C8.0296 16.849 7.64905 16.4796 7.64905 16.0265V13.8551C7.13953 13.5694 6.79493 13.0408 6.79493 12.4306C6.79493 11.5224 7.56025 10.7837 8.50106 10.7837C9.44186 10.7837 10.2051 11.5224 10.2051 12.4306C10.2072 13.0408 9.86258 13.5694 9.35307 13.8551Z"/>
                  </svg>
                  <template v-if="!sending">
                    {{ $t('PAYOUT_FORM.SEND_BUTTON') }}
                  </template>
                  <template v-else-if="sending">
                    {{ $t('PAYOUT_FORM.PROCESSING') }}
                  </template>
                </button>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <FormFooter :brand="brandInfo" v-bind:showCardIcons="method === 'CARD'"></FormFooter>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import {PayoutsFormApiService} from '@/services/api/payouts/form-payouts.api.service';
import FormFooter from '@/components/form/pay/FormFooter.vue';
import {b64DecodeUnicode} from '@/utils/utils';
import {buildDemoFormBrand, TEST_PAYOUT_FORM_DATA} from '@/utils/test';
import {FormBrandSrv} from '@/services/FormBrandSrv';
import CardMethodForm from '@/components/form/payout/methods/CardMethodForm.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import {MatomoUtils} from '@/matomo';
import DestinationFormData = com.paidora.payouts.app.core.models.DestinationFormData;
import DestinationFormSubmitRequest = com.paidora.payouts.app.form.requests.DestinationFormSubmitRequest;
import DataResponse = com.paidora.framework.common.web.models.responses.DataResponse;
import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;
import PayoutMethodType = com.paidora.payouts.types.enums.PayoutMethodType;

//@ts-ignore
@Component({
  components: {
    FormField,
    FormRow,
    FormContent,
    FormCol,
    FormFooter,
    Spinner,
    CardMethodForm,
    McMethodForm: () => import('@/components/form/payout/methods/McMethodForm.vue')
  }
})
export default class PayoutDestinationForm extends Vue {
  formData?: DestinationFormData;

  @Ref('payoutDataForm') readonly payoutDataForm!: HTMLFormElement;
  @Ref('payoutDataFormSubmit') readonly payoutDataFormSubmit!: HTMLElement;

  confirmed = false;
  confirmedInvalid = false;
  sending = false;
  loading = true;
  error = '';
  currentFormComponent = '';

  isValid = false;

  get brandInfo(): FormBrandInfo {
    return this.formData?.brandInfo as FormBrandInfo;
  }

  get method(): PayoutMethodType {
    return this.formData?.method as PayoutMethodType;
  }

  get returnUrl(): string {
    return this.formData?.returnUrl as string;
  }

  get isIFrame() {
    return window.self != window.top || window.location !== window.parent.location;
  }

  async mounted(): Promise<void> {
    var token = this.$route.params.token as string;
    if (!token) {
      this.error = 'ERROR.TOKEN';
      return;
    }
    if (token === 'test') {
      this.loadTestData();
    } else {
      new PayoutsFormApiService().form(token as string, {
        referer: document.referrer,
        host: window.location.hostname
      }).then((r: DataResponse<DestinationFormData>) => {
        if (r.success) {
          this.loading = false;
          if (r.data) {
            this.formData = r.data;
            if (this.formData?.brandInfo) {
              FormBrandSrv.applyBrand(this.formData.brandInfo as FormBrandInfo);
            }
            this.switchMethodForm(this.formData);
          }
        } else {
          this.error = r.error;
        }
      }, () => this.error = 'ERROR.TOKEN');
    }
  }

  @Watch('$route', {immediate: false, deep: true})
  onUrlChange(newVal: any) {
    var token = newVal.params.token as string;
    if (token === 'test') {
      this.loadTestData();
    }
  }

  submitForm() {
    this.payoutDataFormSubmit.click();
  }

  submitPayoutData() {
    this.confirmedInvalid = !this.confirmed;
    if (!this.isValid || this.confirmedInvalid) {
      this.$toast.error(this.$t('ERROR.FORM_FIELDS') as string);
      return;
    }

    const elements = Array.from(this.payoutDataForm) as HTMLInputElement[];
    const values = elements.reduce((acc: { [key: string]: string }, el: HTMLInputElement) => {
      if (el.name && el.name != 'all_correct') {
        acc[el.name] = el.value;
      }
      return acc;
    }, {});

    let request: DestinationFormSubmitRequest = {
      values: values
    };

    this.sending = true;
    if (this.formData!.token == 'test') {
      document.location.href = this.formData!.returnUrl!;
      this.sending = true;
      return;
    }
    new PayoutsFormApiService().submit(this.formData!.token!, request)
      .then(r => {
        this.sending = false;
        if (r.success) {
          document.location.href = this.formData!.returnUrl!;
          this.sending = true;
        } else {
          this.error = r.error;
        }
      }, error => {
        this.sending = false;
        this.error = 'ERROR.ERROR';
      });

  }

  navBack() {
    MatomoUtils.sendRedirectInteraction('backUrl', 'header navigation back button');
    document.location.href = this.returnUrl as unknown as string;
  }

  protected validForm(valid: boolean) {
    this.isValid = valid;
  }

  private loadTestData() {
    let method = this.$route.query.method || 'CARD';
    let hash = this.$route.hash;
    let brandInfo: FormBrandInfo | undefined = undefined;
    if (hash) {
      brandInfo = JSON.parse(b64DecodeUnicode(decodeURI(hash.substr(1))));
    }
    this.loading = false;
    this.formData = {
      ...TEST_PAYOUT_FORM_DATA,
      ...{
        method: method,
        formExpiration: new Date(new Date().setMinutes(new Date().getMinutes() + 5)),
        brandInfo: brandInfo || buildDemoFormBrand(this.$route.query)
      }
    } as DestinationFormData;
    FormBrandSrv.applyBrand(this.formData.brandInfo);
    this.switchMethodForm(this.formData);
  }

  private switchMethodForm(formData: DestinationFormData) {
    switch (formData.method) {
      case 'CARD':
        this.currentFormComponent = 'CardMethodForm';
        break;
      case 'MOBILE':
        this.currentFormComponent = 'McMethodForm';
        break;
      case 'QIWI':
        this.currentFormComponent = 'McMethodForm';
        break;
      default:
        this.error = 'ERROR.METHOD_NOT_AVAILABLE';
    }
  }
}
</script>

<style lang="scss" scoped>

.payout-label {
  display: flex;
  flex-direction: column;

  .order-text {
    display: inline-block;
    font-size: 20px;
    line-height: 22px;
    padding-bottom: 12px;
    font-weight: 400;
  }

  .method-text {
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 14px;
    font-weight: 350;
  }
}

.payouts-destination-confirmation {
  margin-top: 12px;

  label {
    cursor: pointer;
  }
}
</style>
