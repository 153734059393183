/* eslint-disable prefer-rest-params */// Generated using typescript-api-generator
import {
  WebApiMapping,
  RequestMapping,
  RequestMethod,
  PathVariable,
  RequestBody,
  RequestParam,
  ResponseBody,
  FormRequest,
  NoAuth,
  Blob,
  NoLoadBroadcasting,
  RequestFormData,
  WebApiServiceBase
} from '@/utils//api';
import {getLocalUrlBase} from '@/utils/urls';

export class BillingFormApiService extends WebApiServiceBase {
  constructor() {
    super(getLocalUrlBase(), 'api/v2/form');
  }

  @RequestMapping('/link', RequestMethod.Post)
  @NoAuth
  @ResponseBody
  public addLink(@RequestBody request: com.paidora.billing.app.form.requests.FormLinkRequest): Promise<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.models.PaymentFormInfo>> {
    return this.makeRequest<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.models.PaymentFormInfo>>(arguments);
  }

  @RequestMapping('/{token}/get', RequestMethod.Post)
  @NoAuth
  @ResponseBody
  public form(@PathVariable('token') token: string, @RequestBody request: com.paidora.billing.app.form.requests.FormGetRequest): Promise<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.models.PaymentFormInfo>> {
    return this.makeRequest<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.models.PaymentFormInfo>>(arguments);
  }

  @RequestMapping('/{transactionToken}/load-raw-qr-link', RequestMethod.Get)
  @NoAuth
  @ResponseBody
  public loadRawQrLink(@PathVariable('transactionToken') transactionToken: string): Promise<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.responses.FormRawQrLinkResponse>> {
    return this.makeRequest<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.responses.FormRawQrLinkResponse>>(arguments);
  }

  @RequestMapping('/ping', RequestMethod.Get)
  @NoAuth
  public ping(): Promise<string> {
    return this.makeRequest<string>(arguments);
  }

  @RequestMapping('/{token}/submit', RequestMethod.Post)
  @NoAuth
  @ResponseBody
  public submit(@PathVariable('token') token: string, @RequestBody request: com.paidora.billing.app.form.requests.FormSubmitRequest): Promise<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.responses.FormSubmitResponse>> {
    return this.makeRequest<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.billing.app.form.responses.FormSubmitResponse>>(arguments);
  }
}
