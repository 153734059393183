<template>
  <FormContent>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="otp"
            :inputName="'otp'"
            :label="'FORM.DATA.OTP'"
            :value="formFields.otp"
            :regexp="/^[0-9']+$/"
            :min="4"
            :max="12"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import TextInputFormField from '@/components/form/common/form/fields/TextInputFormField.vue';

@Component(
  {
    components: {
      TextInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })
export default class OtpDataForm extends DataFormBase implements IForm {
  constructor() {
    super();
    this.formFields = {
      otp: ''
    };
  }

  fieldChanged() {
    this.validate();
  }
}
</script>

<style lang="scss" scoped>
</style>
