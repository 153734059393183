<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}"
       @click="focusCvvField">
    <Modal v-if="showModal && modalText" v-bind:body="modalText ? modalText : ''" v-bind:header="modalHeader" @close="closeModalWindow()"></Modal>
    <input
      id="cardCvv"
      ref="cardCvv"
      v-number-only
      :value="inputValue"
      autocomplete="cc-csc"
      class="card-input__input brand-secondary brand-primary-focus"
      data-card-field
      inputmode="numeric"
      maxlength="4"
      name="cvc"
      placeholder="CVV/CVC"
      type="password"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changeCvv($event.target.value)"
    />
    <div class="c-info-icon" @click="showModalWindow()">
      <img :src="require(`@/assets/svg/details.svg`)" alt=""/>
    </div>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import {numberOnlyDirective} from '@/components/form/common/form/directives';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';
import Modal from "@/components/common/Modal.vue";

@Component({
  components: {Modal},
  directives: {
    'number-only': numberOnlyDirective
  }
})
export default class CardCvvInputFormField extends InputFormFieldBase<string> {

  showModal = false;
  modalText = String(this.$t('FORM.CARD.CVC_INFO'));
  modalHeader = ''

  constructor() {
    super();
    this.inputValue = '';
  }

  focusCvvField() {
    (this.$refs.cardCvv as any).focus();
  }

  changeCvv(value: string) {
    this.inputValue = value;
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: false
    });
  }

  showModalWindow() {
    this.showModal = true;
  }

  closeModalWindow() {
    this.showModal = false;
  }

}
</script>

<style scoped>

</style>
