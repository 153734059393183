import {
  ContainerType,
  FormDataContainer,
  PathVariableContainer,
  RequestBodyContainer,
  RequestMethod,
  RequestParamContainer,
  RequestType,
  ResponceType
} from './containers';
import axios from 'axios';

export class WebApiServiceBase {
  constructor(
    protected baseUri: string,
    protected basePath: string) {
  }


  protected static handleError(error: Response) {
    console.error(error);
    return Promise.reject((<any>error.json()).error || 'Server error');
  }

  protected getHeaders(noAuth: boolean, jsonBody: boolean): { [name: string]: string } {
    const headers: { [name: string]: string } = {};
    headers['Accept'] = 'application/json';
    // disable IE ajax request caching
    headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    // extra
    headers['Cache-Control'] = 'no-cache';
    headers ['Pragma'] = 'no-cache';
    if (jsonBody) {
      headers['Content-Type'] = 'application/json';
    }
    return headers;
  }

  protected getFormHeaders(noAuth: boolean, formData: boolean): { [name: string]: string } {
    const headers: { [name: string]: string } = {};
    headers['Accept'] = 'application/json';
    if (!formData) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    // disable IE ajax request caching
    headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    // extra
    headers['Cache-Control'] = 'no-cache';
    headers['Pragma'] = 'no-cache';

    return headers;
  }

  protected makeRequest<T>(args: any): Promise<T> {
    try {
      return this.makeRequestInternal<T>(args[0], args[1], args[2], typeof args[3] == 'undefined' ? ResponceType.Json : args[3], typeof args[4] == 'undefined' ? RequestType.Json : args[4], args[5]);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  private trimStringParams(params: string | object | any[]): string | object | any[] {
    if (typeof params === 'object') {
      if (Array.isArray(params)) {
        return params.map(v => this.trimStringParams(v));
      } else {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            // @ts-ignore
            params[key] = this.trimStringParams(params[key]);
          }
        }
        return params;
      }
    } else {
      return ((params ? params.toString() : null) || '').trim();
    }
  }

  private static addQueryParam(query: string, name: string, value: string): string {
    const normalizedValue = typeof value === 'undefined' || value === null ? '' : value;
    return `${query}${query != '' ? '&' : ''}${name}=${encodeURIComponent(normalizedValue.toString().trim())}`;
  }

  private appendValueToFormData(formData: FormData, name: string, value: any) {
    if (value instanceof File) {
      formData.append(name, value, (<File>value).name);
    } else if (Array.isArray(value) || typeof value === 'object' && value !== null) {
      const preparedValue = this.trimStringParams(value);
      formData.append(name, JSON.stringify(preparedValue));
    } else if (typeof value !== 'undefined' && value !== null) {
      const preparedValue = typeof value === 'string' ? value.trim() : value;
      formData.append(name, preparedValue);
    }
  }

  private generateFormData(value: any) {
    const formData = new FormData();
    const keys = Object.keys(value);
    keys.forEach(k => {
      const val = value[k];
      if (Array.isArray(val)) {
        const arrayKey = `${k}[]`;
        val.forEach(v => this.appendValueToFormData(formData, arrayKey, v));
      } else {
        this.appendValueToFormData(formData, k, val);
      }
    });
    return formData;
  }

  private generateFullPath(compiledPath: string) {
    return (this.baseUri.endsWith('/') ? this.baseUri.substring(0, this.baseUri.length - 1) : this.baseUri)
      + '/'
      + (this.basePath.startsWith('/') ? this.basePath.substring(1) : this.basePath)
      + (this.basePath && !this.basePath.endsWith('/') && !compiledPath.startsWith('/') ? '/' : '')
      + compiledPath;
  }


  private makeRequestInternal<T>(path: string, method: RequestMethod, parameters: RequestParamContainer[], responceType: ResponceType, requestType: RequestType, noAuth: boolean): Promise<T> {
    let query = '';
    let compiledPath = path;
    let body: any;
    let formData: FormData;
    parameters.filter((e) => e.type == ContainerType.RequestParam).forEach(
      (e: RequestParamContainer) => {
        if (typeof e.value != 'undefined') {
          const vals = Array.isArray(e.value) ? e.value : [e.value];
          for (let i = 0; i < vals.length; i++) {
            query = WebApiServiceBase.addQueryParam(query, e.name, vals[i]);
          }
        } else if (e.required) {
          throw new Error(`RequestParam ${e.name} can't be undefined.`);
        }

      });
    parameters.filter((e) => e.type == ContainerType.PathVariable).forEach((e: PathVariableContainer) => {
      if (typeof e.value != 'undefined') {
        compiledPath = compiledPath.replace(`{${e.name}}`, e.value);
      }
    });
    parameters.filter((e) => e.type == ContainerType.RequestBody).forEach((e: RequestBodyContainer) => {
      if (typeof e.value != 'undefined') {
        if (typeof body != 'undefined') {
          throw new Error(`Can't use multiple RequestBody params.`);
        }
        body = this.trimStringParams(e.value);
      }
    });
    parameters.filter((e) => e.type == ContainerType.FormData)
      .forEach((e: FormDataContainer) => {
        if (typeof e.value != 'undefined') {
          if (typeof formData != 'undefined') {
            throw new Error(`Can't use multiple FormData params.`);
          }
          formData = this.generateFormData(e.value);
        }
      });
    const requestPathBase = this.generateFullPath(compiledPath);
    let requestPath = requestPathBase + `${query ? '?' + query : ''}`;
    let observable: Promise<any> | null = null;
    switch (responceType) {
      case ResponceType.Json:
        switch (method) {
          case RequestMethod.Get:
            observable = axios.get(requestPath, {
              headers: this.getHeaders(noAuth, false)
            });
            break;
          case RequestMethod.Put:
            observable = axios
              .put(requestPath, JSON.stringify(body), {
                headers: this.getHeaders(noAuth, true)
              });
            break;
          case RequestMethod.Delete:
            observable = axios.delete(requestPath, {
              headers: this.getHeaders(noAuth, false)
            });
            break;
          case RequestMethod.Post:
            switch (requestType) {
              case RequestType.Json:
                observable = axios
                  .post(requestPath, JSON.stringify(body), {
                    headers: this.getHeaders(noAuth, true)
                  });
                break;
              case RequestType.Form:
                requestPath = requestPathBase;
                // @ts-ignore
                observable = axios.post(requestPath, formData || query, {
                  // @ts-ignore
                  headers: this.getFormHeaders(noAuth, !!formData)
                });

                break;
              default:
                throw new Error(`Unknown RequestType: ${requestType}`);
            }
            break;
          default:
            throw new Error(`Unknown RequestMethod: ${method}`);
        }
        observable = observable.then((response) => response.data);
        break;
      default:
        throw new Error(`Unknown ResponceType: ${responceType}`);
    }
    return observable;
  }
}
