<template>
  <div class="form-root">
    <spinner v-if="loading"></spinner>
    <div v-if="!loading">
      <FormHeader v-bind:amount="formData.amount"
                  v-bind:back-url="formData.backUrl"
                  v-bind:brand="brandInfo"
                  v-bind:currency="formData.currency"
                  v-bind:currency-scale="formData.currencyScale"
                  v-bind:description="formData.description"
                  v-bind:method="formData.paymentMethod"
                  v-bind:orderId="formData.orderId"
                  v-bind:return-url="formData.returnUrl"
      />
      <template>
        <component v-bind:is="currentFormComponent" v-bind="{formData: formData}"
                   @expired="expired" @form-data-changed="formDataChanged" @on-submit="submit"></component>
      </template>
      <FormFooter
        v-bind:brand="brandInfo"
        v-bind:showCardIcons="formData.paymentMethod === 'CARD'"/>
    </div>
  </div>
</template>

<style lang="scss">

</style>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {BillingFormApiService} from '@/services/api/billing/form-billing.api.service';
import PaymentData from '@/components/form/pay/steps/PaymentData.vue';
import PaymentMethods from '@/components/form/pay/steps/PaymentMethods.vue';
import PaymentStatus from '@/components/form/pay/steps/PaymentStatus.vue';
import Spinner from '@/components/common/Spinner.vue';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Logo from '@/components/form/common/Logo.vue';
import FormHeader from '@/components/form/pay/FormHeader.vue';
import FormFooter from '@/components/form/pay/FormFooter.vue';
import LoadingComponent from '@/components/form/pay/steps/LoadingComponent.vue';
import {MatomoUtils} from '@/matomo';
import {version} from '@/version';
import {buildDemoFormBrand, TEST_FORM_DATA} from '@/utils/test';
import {FormBrandSrv} from '@/services/FormBrandSrv';
import {b64DecodeUnicode} from '@/utils/utils';
import CustomerData from '@/components/form/pay/steps/CustomerData.vue';
import OtpData from '@/components/form/pay/steps/OtpData.vue';
import P2PMethodTypes from '@/components/form/pay/steps/types/P2PMethodTypes.vue';
import P2PDestinationInfo from '@/components/form/pay/steps/forms/p2p/P2PDestinationInfo.vue';
import MultibancoDestinationInfo from '@/components/form/pay/steps/forms/multibanco/MultibancoDestinationInfo.vue';
import DataResponse = com.paidora.framework.common.web.models.responses.DataResponse;
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;
import FormSubmitRequest = com.paidora.billing.app.form.requests.FormSubmitRequest;
import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;

@Component({
  components: {
    CustomerData,
    FormFooter,
    FormHeader,
    LoadingComponent,
    Logo,
    OtpData,
    PaymentData,
    PaymentMethods,
    P2PMethodTypes,
    P2PDestinationInfo,
    MultibancoDestinationInfo,
    PaymentStatus,
    Spinner
  }
})
export default class PayForm extends Vue {
  loading = true;
  error? = '';
  currentFormComponent = 'LoadingComponent';
  formData?: PaymentFormInfo;

  get brandInfo(): FormBrandInfo {
    return this.formData?.brandInfo as FormBrandInfo;
  }

  async mounted(): Promise<void> {
    let fp = null;
    await FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        fp = result.visitorId;
      });

    var token = this.$route.params.token as string;
    if (!token) {
      this.setError('ERROR.TOKEN');
      return;
    }

    if (token === 'test') {
      this.loadTestData();
    } else {
      this.currentFormComponent = 'LoadingComponent';
      MatomoUtils.setTransactionID(token);
      MatomoUtils.sendOpenInteraction(version, '');
      this.loadActualFormData(token, fp as unknown as string);
    }
  }

  @Watch('$route', {immediate: false, deep: true})
  onUrlChange(newVal: any) {
    var token = newVal.params.token as string;
    if (token === 'test') {
      this.loadTestData();
    }
  }

  loadActualFormData(token: string | undefined, fp: string) {
    new BillingFormApiService().form(token as string, {
      referer: document.referrer,
      fp: fp as unknown as string
    })
      .then((r: DataResponse<PaymentFormInfo>) => {
        if (r.success) {
          this.loading = false;
          this.formData = r.data;
          if (this.formData?.brandInfo) {
            FormBrandSrv.applyBrand(this.formData.brandInfo as FormBrandInfo);
          }

          this.formStepDecision(this.formData as PaymentFormInfo);
        } else {
          this.setError(r.error);
        }
      }, () => {
        this.setError('ERROR.TOKEN');
      }).catch(err => {
      this.setError(err);
    });
  }

  setError(error?: string) {
    this.loading = false;
    MatomoUtils.sendErrorInteraction('Error', error ? error : 'Unknown error');
    if (this.formData) {

      let erroredFormData = Object.assign({}, this.formData);
      erroredFormData.formStatus = 'FAILED';
      erroredFormData.formStatusDetails = error as string;
      this.formStepDecision(erroredFormData);
    }

  }

  formDataChanged(formData: PaymentFormInfo) {
    let token = formData.token;

    this.currentFormComponent = 'LoadingComponent';
    if (formData.formStatus !== this.formData?.formStatus) {
      this.formData = formData;
      this.formStepDecision(formData);
      return;
    }

    if (token.startsWith('test')) {
      setTimeout(() => {
        this.formStepDecision(formData);
      }, 3000);
    } else {
      let fp = null;
      FingerprintJS.load()
        .then((fp) => fp.get())
        .then((result) => {
          fp = result.visitorId;
          this.loadActualFormData(token, fp);
        });
    }
  }

  expired(): void {
    if (this.formData) {
      this.formData.formStatus = 'EXPIRED';
      this.formStepDecision(this.formData);
    }
  }

  submit(token: string, request: FormSubmitRequest) {
    MatomoUtils.sendClickButtonInteraction('Submit', 'Submit');
    if (this.formData) {
      this.formData.formStatus = 'SUBMITTED';
    }

    this.currentFormComponent = 'LoadingComponent';
    new BillingFormApiService().submit(token, request)
      .then(r => {
        this.loading = false;
        if (r.success) {
          const next = r.data;
          switch (next.type) {
            case 'METHOD':
            case 'CUSTOMER_DATA':
            case 'METHOD_TYPE':
              this.formDataChanged(next.paymentFormInfo);
              break;
            case 'SUBMIT_DATA':
            case 'OTP_DATA':
              switch (next.processingStatus) {
                case 'REDIRECT':
                  MatomoUtils.sendRedirectInteraction('3dsRedirect', `${next.redirectUrl}`);
                  document.location.href = next.redirectUrl as string;
                  return;
                case 'OTP_REQUIRED':
                  if (next.paymentFormInfo) {
                    this.formDataChanged(next.paymentFormInfo);
                  }
                  return;
                case 'METHOD_PENDING':
                  if (next.paymentFormInfo.methodDestinationInfo) {
                    this.formDataChanged(next.paymentFormInfo);
                  }
                  return;
                case 'SUCCESS':
                  if (next.paymentFormInfo) {
                    this.formDataChanged(next.paymentFormInfo);
                  }
                  return;
                case 'FAILED':
                  if (next.transactionToken == null) {
                    this.setError(next.error);
                    return;
                  } else {
                    if (next.paymentFormInfo) {
                      this.formDataChanged(next.paymentFormInfo);
                    }
                    return;
                  }
              }
              break;
          }
        } else {
          if (this.formData) {
            this.formDataChanged(this.formData);
          }
        }
      }, error => {
        this.loading = false;
        this.setError(error);
      });

  }

  private formStepDecision(formData: PaymentFormInfo) {
    switch (formData.formStatus) {
      case 'NOT_FILLED_METHOD':
        this.currentFormComponent = 'PaymentMethods';
        break;
      case 'NOT_FILLED_CUSTOMER_DATA':
        this.currentFormComponent = 'CustomerData';
        break;
      case 'NOT_FILED_METHOD_TYPE':
        this.currentFormComponent = 'P2PMethodTypes';
        break;
      case 'SHOW_DESTINATION':
        if (formData.paymentMethod == 'MULTIBANCO') {
          this.currentFormComponent = 'MultibancoDestinationInfo';
        } else {
          this.currentFormComponent = 'P2PDestinationInfo';
        }
        break;
      case 'WAITING_OTP':
        this.currentFormComponent = 'OtpData';
        break;
      case 'WAITING_INPUT':
      case 'INCOMPLETE':
        MatomoUtils.sendFormStatusChangedInteraction('New', formData.formStatus);
        this.currentFormComponent = 'PaymentData';
        break;
      case 'EXPIRED':
      case 'PROCESSED':
      case 'FAILED':
        MatomoUtils.sendFormStatusChangedInteraction('Final', formData.formStatus);
        this.currentFormComponent = 'PaymentStatus';
        break;
      case 'SUBMITTED':
        this.currentFormComponent = 'LoadingComponent';
        break;
      default:
        this.setError('ERROR.TOKEN');
    }
  }

  private loadTestData() {
    let method = this.$route.query.method || 'CARD';
    let amount = this.$route.query.amount || 1000;
    let currency = this.$route.query.currency || 'USD';
    let hash = this.$route.hash;
    let brandInfo: FormBrandInfo | undefined = undefined;
    if (hash) {
      brandInfo = JSON.parse(b64DecodeUnicode(decodeURI(hash.substr(1))));
    }
    this.loading = false;
    this.formData = {
      ...TEST_FORM_DATA,
      ...{
        paymentMethod: method,
        amount: amount,
        currency: currency,
        formExpirationDelta: 5 * 60 * 1000,
        brandInfo: brandInfo || buildDemoFormBrand(this.$route.query),
        backUrl: this.$route.query['backUrl'] || TEST_FORM_DATA.backUrl,
        returnUrl: this.$route.query['returnUrl'] || TEST_FORM_DATA.returnUrl
      }
    } as PaymentFormInfo;
    FormBrandSrv.applyBrand(this.formData.brandInfo);
    this.formStepDecision(this.formData);
  }
}
</script>
