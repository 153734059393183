<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}">
    <VuePhoneNumberInput
      ref="phoneInput"
      v-model="msisdnModel"
      :error="invalid"
      :translations="{ countrySelectorLabel: $t('FORM.MSISDN.COUNTRY_CODE'), countrySelectorError: $t('FORM.MSISDN.COUNTRY_CODE_ERROR'),  phoneNumberLabel: $t('FORM.MSISDN.LABEL') ,  example: $t('FORM.MSISDN.EXAMPLE')}"
      required
      :default-country-code="countryIsoCode"
      @update="changeMsisdn($event)"
    />
    <input :name="inputName || 'msisdn'"
           :value="inputValue"
           type="hidden"/>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import {cardHolderDirective} from '@/components/form/common/form/directives';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';
import VuePhoneNumberInput from '@/components/common/VuePhoneNumberInput/index.vue';
import {Prop} from 'vue-property-decorator';

@Component({
  components: {
    VuePhoneNumberInput
  },
  directives: {
    'card-holder': cardHolderDirective
  }
})
export default class MobilePhoneInputFormField extends InputFormFieldBase<string> {
  result: any = {pristine: true, isValid: true};
  msisdnModel = '';

  @Prop()
  readonly countryIsoCode!: string;

  constructor() {
    super();
    this.inputValue = '';
    this.msisdnModel = this.inputValue;
  }

  changeMsisdn(event: any) {
    this.result = event;
    this.inputValue = this.result.e164;
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: false
    });
  }

  isInvalid(): boolean {
    return !this.inputValue || !this.result.isValid;
  }

  getErrorMessage(): string {
    return 'ERROR.MSISDN';
  }
}
</script>

<style scoped>

</style>
