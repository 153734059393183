<template>
  <div style="flex: 1;">
    <form ref="dataForm">
      <component v-bind:is="'MultibancoDestinationData'" ref="formComponent"
                 v-bind="{formData: formData}"></component>
      <input ref="dataFormSubmit" style="display:none" type="submit">
      <div v-if="error" class="error-label brand-error">
        <span class="c-error-line">{{ $t(error) }}</span>
      </div>
      <div class="panel-footer">
        <div class="panel-footer-submit">
          <button class="button-pay button-accent brand-button-accent  brand-button-border-radius"
                  type="button"
                  v-on:click="submitForm()">
            <template>
              {{ $t('FORM.NEXT') }}
            </template>
          </button>
        </div>
      </div>
    </form>
    <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
    <div class="panel-footer">
    </div>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {Ref} from 'vue-property-decorator';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import FormCol from "@/components/form/common/form/layout/FormCol.vue";
import FormContent from "@/components/form/common/form/layout/FormContent.vue";
import TextInputFormField from "@/components/form/common/form/fields/TextInputFormField.vue";
import FormRow from "@/components/form/common/form/layout/FormRow.vue";
import FormField from "@/components/form/common/form/FormField.vue";
import MultibancoDestinationData from "@/components/form/pay/steps/forms/multibanco/MultibancoDestinationData.vue";

@Component({
  components: {
    FormField, FormRow, TextInputFormField, FormContent, FormCol,
    FormTimer, MultibancoDestinationData
  }
})
export default class MultibancoDestinationInfo extends PayStepBase {
  @Ref('dataForm') readonly dataForm!: HTMLFormElement;
  @Ref('dataFormSubmit') readonly dataFormSubmit!: HTMLElement;
  @Ref('formComponent') readonly formComponent!: IForm;
  isValid = false;

  mounted(): void {
    this.startExpirationTimer();
  }

  submitForm() {
    this.dataFormSubmit.click();
  }
}
</script>

<style scoped lang="scss">
</style>
