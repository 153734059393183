<template>
  <div v-if="brand && brand.logo && brand.companyUrl" class="logo-panel">
    <a :href="brand.companyUrl" target="_blank">
      <img :src="brand.logo" alt="" class="c-logo"/>
    </a>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;

@Component
export default class Logo extends Vue {
  @Prop()
  brand!: FormBrandInfo;
}
</script>

<style scoped>
.logo-panel {
  position: fixed;
  left: 15px;
  top: 5px;
}
</style>
