<template>
  <div class="form-root">
    <Modal v-if="showModal && modalText" v-bind:body="modalText ? modalText : ''" v-bind:header="modalHeader" @close="closeModalWindow()"></Modal>
    <div class="status-label no-select">{{ $t('FORM.COMMON_ERROR') }}</div>
    <div class="status-bill">
      <img :src="require(`@/assets/svg/error.svg`)" alt="">
    </div>
    <div class="panel-footer">
      <div class="panel-footer-submit">
        <button v-if="modalText" class="button-black brand-button-border-radius" type="button" @click="showModalWindow()">
          {{ $t('FORM.BUTTON.WHAT_HAPPENED') }}
        </button>
      </div>
    </div>
    <ErrorFooter style="margin-top: 25px"/>
  </div>
</template>

<script lang="ts">
import ErrorFooter from '@/components/form/error/ErrorFooter.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import Modal from '@/components/common/Modal.vue';

@Component({
  components: {
    ErrorFooter,
    Modal
  }
})
export default class Error extends Vue {
  showModal = false;

  modalText?: string;
  modalHeader?: string = '';

  constructor() {
    super();
    if (this.$route.params.text) {
      this.modalText = this.$route.params.text;
    } else {
      this.modalText = '';
    }
  }

  showModalWindow(): void {
    this.showModal = true;
  }

  closeModalWindow(): void {
    this.showModal = false;
  }
}
</script>

<style lang="scss" scoped>
.status-label {
  margin-top: 42px;
  text-align: center;
  font-family: Montserrat-Bold, sans-serif;
  font-size: 28px;
}

.status-bill {
  height: 165px;
  margin: 10px 42px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  justify-content: space-around;
}
</style>
