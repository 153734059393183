<template>
  <div class="timer no-select">
    <template v-if="displaySeconds">{{ $t('FORM.TIMER.TIME_LEFT') }} <span v-if="Number(displayDays) > 0">{{
        displayDays
      }} {{ $t('FORM.TIMER.DAYS') }} </span><span v-if="Number(displayHours) > 0">{{
        displayHours
      }} {{ $t('FORM.TIMER.HOURS') }} </span><span
      v-if="Number(displayMinutes) > 0">{{ displayMinutes }} {{ $t('FORM.TIMER.MINS') }} </span><span
      v-if="Number(displaySeconds) > 0">{{ displaySeconds }} {{ $t('FORM.TIMER.SECS') }}</span>
    </template>
  </div>
</template>

<script lang="ts">
import {Log} from '@/utils/log';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

const msInSecond = 1000;
const msInMinute = 60 * msInSecond;
const msInHour = 60 * msInMinute;
const msInDay = 24 * msInHour;
const formatNumber: (num: number) => string = num => {
  return num < 10 ? `0${num}` : String(num);
};

export interface FormTimeOptions {
  end?: Date;
}

@Component({})
export default class FormTimer extends Vue {

  @Prop()
  options!: FormTimeOptions;

  displayDays = '';
  displayHours = '';
  displayMinutes = '';
  displaySeconds = '';
  private _expirationTimer!: number;

  protected calculateRemaining(): boolean {
    if (!this.options?.end) {
      return true;
    }
    const now = new Date();

    const distance = this.options.end.getTime() - now.getTime();
    if (distance <= 0) {
      this.$emit('expired');
      return false;
    }

    const days = Math.floor(distance / msInDay);
    const hours = Math.floor(distance % msInDay / msInHour);
    const minutes = Math.floor((distance % msInHour) / msInMinute);
    const seconds = Math.floor((distance % msInMinute) / msInSecond);

    this.displayDays = formatNumber(days);
    this.displayHours = formatNumber(hours);
    this.displayMinutes = formatNumber(minutes);
    this.displaySeconds = formatNumber(seconds);
    return true;
  }


  mounted(): void {
    if (this.calculateRemaining()) {
      this._expirationTimer = setInterval(() => {
        if (!this.calculateRemaining()) {
          clearInterval(this._expirationTimer);
        }
      }, 1000);
    }
  }

  destroyed(): void {
    if (this._expirationTimer) {
      clearInterval(this._expirationTimer);
    }
  }
}
</script>

<style lang="scss" scoped>
.timer {
  font-family: Montserrat-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  line-height: 17px;
  margin-top: 15px;
  opacity: 0.5;
  min-height: 17px;
}
</style>
