import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import FormRoot from '@/components/form/FormRoot.vue';
import PayForm from '@/components/form/pay/PayForm.vue';
import PayoutDestinationForm from '@/components/form/payout/PayoutDestinationForm.vue';
import Error from '@/components/form/error/Error.vue';
import Test3dSecure from '@/components/form/test/Test3dSecure.vue';
import QrPaymentForm from '@/components/form/qr/QrPaymentForm.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: FormRoot
  },
  {
    path: '/form',
    component: FormRoot
  },
  {
    path: '/pay/:token/',
    component: PayForm
  },
  {
    path: '/payout-destination/:token/',
    component: PayoutDestinationForm
  },
  {
    path: '/test/3dsecure',
    component: Test3dSecure
  },
  {
    path: '/qr/:token/',
    component: QrPaymentForm
  },
  {
    path: '/qr-2/:token/:url',
    component: QrPaymentForm
  },
  {
    path: '/error',
    component: Error
  }
];


const router = new VueRouter({
  mode: 'history',
  // @ts-ignore
  base: process.env.BASE_URL,
  routes
});

export default router;
