import { render, staticRenderFns } from "./P2PMethodTypes.vue?vue&type=template&id=52c7eee9&scoped=true"
import script from "./P2PMethodTypes.vue?vue&type=script&lang=ts"
export * from "./P2PMethodTypes.vue?vue&type=script&lang=ts"
import style0 from "./P2PMethodTypes.vue?vue&type=style&index=0&id=52c7eee9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c7eee9",
  null
  
)

export default component.exports