<template>
  <div style="flex: 1;">
    <div class="customer-data__label">{{ $t('FORM.OTP.LABEL') }}</div>
    <form ref="dataForm" @submit.prevent="submitPaymentData()">
      <component v-bind:is="'OtpDataForm'" ref="formComponent"
                 v-bind="{formData: formData}" @valid-form="validForm"></component>
      <input ref="dataFormSubmit" style="display:none" type="submit">
      <div v-if="error" class="error-label brand-error">
        <span class="c-error-line">{{ $t(error) }}</span>
      </div>
    </form>
    <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
    <div class="panel-footer">
      <div class="panel-footer-submit">
        <button class="button-pay button-accent brand-button-accent  brand-button-border-radius"
                type="button"
                v-on:click="submitForm()">
          <template>
            {{ $t('FORM.NEXT') }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {Ref} from 'vue-property-decorator';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import OtpDataForm from '@/components/form/pay/steps/forms/OtpDataForm.vue';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import FormSubmitRequest = com.paidora.billing.app.form.requests.FormSubmitRequest;

@Component({
  components: {
    FormTimer,
    OtpDataForm
  }
})
export default class OtpData extends PayStepBase {
  @Ref('dataForm') readonly dataForm!: HTMLFormElement;
  @Ref('dataFormSubmit') readonly dataFormSubmit!: HTMLElement;
  @Ref('formComponent') readonly formComponent!: IForm;
  isValid = false;
  submitting = false;

  mounted(): void {
    this.startExpirationTimer();
  }

  submitPaymentData() {
    if (this.submitting) {
      return;
    }

    this.formComponent.validate();
    if (!this.isValid) {
      this.$toast.error(this.$t('ERROR.FORM_FIELDS') as string);
      return;
    }

    const elements = Array.from(this.dataForm) as HTMLInputElement[];
    let values = elements.reduce((acc: { [key: string]: string }, el: HTMLInputElement) => {
      if (el.name) {
        acc[el.name] = el.value;
      }
      return acc;
    }, {});

    let request: FormSubmitRequest = {
      values: values,
      type: 'OTP_DATA',
      browserInfo: <any>null,
      method: this.formData.paymentMethod,
      methodType: this.formData.serviceMethodType
    };
    this.$emit('on-submit', this.formData.token, request);
  }


  submitForm() {
    this.dataFormSubmit.click();
  }

  protected validForm(valid: boolean) {
    this.isValid = valid;
  }

  protected setError(error?: string | undefined) {
    this.error = error || 'ERROR.COMMON';
  }
}
</script>

<style scoped lang="scss">
.customer-data__label {
  display: flex;
  font-size: 14px;
  margin: 24px 0 0;
  justify-content: center;
}
</style>
