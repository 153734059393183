import { render, staticRenderFns } from "./PaymentData.vue?vue&type=template&id=027fde5b&scoped=true"
import script from "./PaymentData.vue?vue&type=script&lang=ts"
export * from "./PaymentData.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027fde5b",
  null
  
)

export default component.exports