import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';
import {MatomoUtils} from '@/matomo';
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;

@Component
export default class DataFormBase extends Vue {
  formFields!: { [id: string]: string };
  invalid = true;
  toSubmit = false;
  @Prop() formData!: PaymentFormInfo;

  constructor() {
    super();
    if (!this.formFields) {
      this.toSubmit =  true;
      this.formNeededToSubmit()
    }
  }

  get formFieldInputs(): InputFormFieldBase<any>[] {
    return (Object.values(this.$refs) as InputFormFieldBase<any>[]).filter(f => f.isFieldInputComponent);
  }

  @Emit('valid-form')
  onFormValidation() {
    return !this.invalid;
  }

  @Emit('submit-form')
  formNeededToSubmit() {
    return this.toSubmit;
  }

  validate(): void {
    this.invalid =  this.formFieldInputs.filter(p => p.inputName != 'cvc').some(p => p.isInvalid());
    this.onFormValidation();
  }

  sendEventToFocusIn(e: any): void {
    if (e.relatedTarget) {
      MatomoUtils.sendFocusInputInteraction('Focus in', `from ${e.relatedTarget.id} to ${e.target.id}`);
    } else {
      MatomoUtils.sendFocusInputInteraction('Focus in', `to ${e.target.id}`);
    }
  }

  sendEventToFocusOut(e: any): void {
    if (e.relatedTarget) {
      MatomoUtils.sendFocusInputInteraction('Focus out', `from ${e.target.id}[${String(e.target.value.length).replace(' ', '')}] to ${e.relatedTarget.id}`);
    } else {
      MatomoUtils.sendFocusInputInteraction('Focus out', `from ${e.target.id}[${String(e.target.value.length).replace(' ', '')}]`);
    }
  }
}
