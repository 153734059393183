<template>
  <div class="footer">
    <div v-if="termsOfService" class="footer-text no-select brand-terms-of-service" v-html="termsOfService">
    </div>
    <div v-if="showCardIcons" class="footer-icons brand-card-icons">
      <CardIcons>
      </CardIcons>
    </div>
    <div v-if="projectLogo" class="footer-project no-select brand-project-logo">
      <img :src="projectLogo" alt="" class="footer-project-img">
    </div>
    <div class="footer-poweredby no-select brand-powered-by">
      <PoweredByPaidora></PoweredByPaidora>
    </div>
  </div>
</template>

<script lang="ts">

import Component from 'vue-class-component';

import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import CardIcons from '@/components/form/common/CardIcons.vue';
import CardMethodForm from '@/components/form/pay/steps/forms/CardMethodForm.vue';
import Modal from '@/components/common/Modal.vue';
import PoweredByPaidora from '@/components/form/common/PoweredByPaidora.vue';
import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;

@Component({
  components: {
    PoweredByPaidora,
    CardIcons,
    CardMethodForm,
    Modal
  }
})
export default class FormFooter extends Vue {
  @Prop()
  brand!: FormBrandInfo;
  @Prop()
  showCardIcons!: boolean;

  get termsOfService(): string {
    return this.brand?.termsOfService;
  }

  get projectLogo(): string {
    return this.brand?.projectLogo;
  }
}
</script>

<style lang="scss" scoped>
.term-of-service {
  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: none;
    outline: none;
  }
}
</style>
