<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}"
       @click="setPanFocus">
    <input
      id="cardPan"
      ref="cardPan"
      :maxlength="cardInputMaxLength"
      :name="inputName || 'pan'"
      :placeholder="$t('FORM.CARD.NUMBER')"
      :value="inputValue"
      autocomplete="cc-number"
      class="card-input__input brand-secondary brand-primary-focus"
      data-card-field
      inputmode="numeric"
      required
      type="tel"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changePan($event)"
    />
    <div v-if="cardType" class="c-card-icon">
      <img :alt="require('@/assets/svg/default-card.svg')" :src="require(`@/assets/cards/${cardType}.svg`)"/>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import creditCardType from 'credit-card-type';
import {numberOnlyDirective} from '@/components/form/common/form/directives';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';

@Component({
  directives: {
    'number-only': numberOnlyDirective
  }
})
export default class CardPanInputFormField extends InputFormFieldBase<string> {
  cardNumberMaxLength = 19;
  cardNumberMinLength = 12;
  cardInputMaxLength = 23;
  cardType = 'generic_old';

  constructor() {
    super();
    this.inputValue = '';
  }

  setPanFocus() {
    (this.$refs.cardPan as any).focus();
  }

  changePan(e: any) {
    this.inputValue = e.target.value;
    let value = this.inputValue.replace(/\D/g, '');
    if (this.inputValue.length > 0) {
      let cardType = creditCardType(value);
      if (cardType.length > 0) {
        var maxLength = cardType.reduce((a, b) => a.lengths.reduce((a, b) => Math.max(a, b)) > b.lengths.reduce((a, b) => Math.max(a, b)) ? a : b);
        var minLength = cardType.reduce((a, b) => a.lengths.reduce((a, b) => Math.min(a, b)) < b.lengths.reduce((a, b) => Math.min(a, b)) ? a : b);
        var maxGaps = cardType.reduce((a, b) => a.gaps.length > b.gaps.length ? a : b);
        this.cardInputMaxLength = maxLength.lengths.reduce((a, b) => Math.max(a, b)) + maxGaps.gaps.length;
        this.cardNumberMinLength = minLength.lengths.reduce((a, b) => Math.min(a, b));
        this.cardNumberMaxLength = maxLength.lengths.reduce((a, b) => Math.max(a, b));
        var gaps = cardType[0].gaps;
        var inputValue = value;
        gaps = gaps.filter(a => a < value.length).sort((a, b) => b - a);
        gaps.forEach(v => {
          inputValue = [inputValue.slice(0, v), ' ', inputValue.slice(v)].join('');
        });
        this.inputValue = inputValue;
      } else {
        this.cardInputMaxLength = 23;
        this.cardNumberMinLength = 12;
        this.cardNumberMaxLength = 19;
      }
    } else {
      this.cardInputMaxLength = 23;
      this.cardNumberMinLength = 12;
      this.cardNumberMaxLength = 19;
    }
    // eslint-disable-next-line eqeqeq
    if (e.inputType == 'deleteContentBackward') {
      let lastChar = this.inputValue.substring(this.inputValue.length, this.inputValue.length - 1);
      // eslint-disable-next-line eqeqeq
      if (lastChar == ' ') {
        this.inputValue = this.inputValue.substring(0, this.inputValue.length - 1);
      }
    }
    if (this.inputValue.length > 0) {
      let cardType = creditCardType(value);
      if (cardType.length) {
        this.cardType = cardType[0].type;
      } else {
        this.cardType = 'generic_old';
      }
    } else {
      this.cardType = 'generic_old';
    }
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: !this.invalid && this.inputValue.length == this.cardNumberMaxLength
    });
  }

  isInvalid(): boolean {
    if (this.inputValue) {
      let number = this.inputValue.replace(/ /g, '');
      if (number.length < this.cardNumberMinLength || number.length > this.cardNumberMaxLength) {
        return true;
      } else {
        return this.cardLuhnCheck();
      }
    } else {
      return true;
    }
  }

  getErrorMessage(): string {
    return 'ERROR.CARD';
  }


  private cardLuhnCheck() {
    let value = this.inputValue.replace(/ /g, '');

    if (/[^0-9-\s]+/.test(value)) return false;

    var nCheck = 0, bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n);
      var nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }
    return (nCheck % 10) !== 0;
  }
}
</script>

<style scoped>

</style>
