<template>
  <div class="form-root">
    <div class="panel panel-default form-panel">
      <div class="payouts-destination-header no-select">{{ $t('TEST.TITLE') }}</div>
      <div class="panel-body">
        <form id="paymentDataForm" ref="paymentDataForm" :action="termUrl" method="post" novalidate>
          <FormContent>
            <FormRow>
              <FormCol>
                <FormField>
                  <OtpInputFormField
                    :input-name="'PaRes'"
                    @changed="changeOtp($event)">
                  </OtpInputFormField>
                </FormField>
              </FormCol>
            </FormRow>
          </FormContent>
          <input :value="MD" name='MD' type="hidden"/>
          <input ref="payoutDataFormSubmit" style="display:none" type="submit">
        </form>
      </div>
      <div class="panel-footer">
        <div class="panel-footer-submit">
          <button class="button-pay button-accent brand-button-accent brand-button-border-radius" type="button" v-on:click="submitForm()">
            {{ $t('TEST.PAY') }}
          </button>
        </div>
      </div>
    </div>
    <FormFooter
      v-bind:brand="brandInfo"
      v-bind:showCardIcons="true"/>
  </div>
</template>

<script lang="ts">
import {Component, Ref, Vue} from 'vue-property-decorator';
import {FormBrandSrv} from '@/services/FormBrandSrv';
import {buildDemoFormBrand} from '@/utils/test';
import FormField from '@/components/form/common/form/FormField.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import OtpInputFormField from '@/components/form/common/form/fields/OtpInputFormField.vue';
import {FormFieldEvent} from '@/components/form/common/form/fields/InputFormFieldBase';
import FormFooter from '@/components/form/pay/FormFooter.vue';
import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;

@Component({
  components: {
    FormFooter,
    FormField,
    FormContent,
    FormRow,
    FormCol,
    OtpInputFormField
  }
})
export default class Test3dSecure extends Vue {
  termUrl = '';
  MD = '';
  isValid = false;
  brandInfo!: FormBrandInfo;

  @Ref('payoutDataFormSubmit') readonly payoutDataFormSubmit!: HTMLElement;

  mounted() {
    let query = this.$route.query;
    this.MD = query.MD as unknown as string;
    this.termUrl = query.TermUrl as unknown as string;
    this.brandInfo = buildDemoFormBrand({});
    FormBrandSrv.applyBrand(this.brandInfo);
  }

  changeOtp(e: FormFieldEvent<string>) {
    console.log(e);
    this.isValid = !e.invalid;
  }

  submitForm() {
    if (!this.isValid) {
      this.$toast.error(this.$t('ERROR.FORM_FIELDS') as string);
      return;
    }
    this.payoutDataFormSubmit.click();
  }
}
</script>

<style lang="scss" scoped>

.payouts-destination-header {
  font-family: Montserrat-Extra-Bold, sans-serif;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 45px;
}
</style>
