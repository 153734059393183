<template>
  <div :id="id" class="vue-phone-number-input">
    <div
      v-if="!noCountrySelector"
      class="select-country-container"
    >
      <CountrySelector
        :id="`${uniqueId}_country_selector`"
        ref="CountrySelector"
        v-model="countryCode"
        :countries-height="countriesHeight"
        :disabled="disabled"
        :error="shouldChooseCountry"
        :hint="shouldChooseCountry ? t.countrySelectorError : null"
        :ignored-countries="ignoredCountries"
        :items="codesCountries"
        :label="t.countrySelectorLabel"
        :no-flags="noFlags"
        :only-countries="onlyCountries"
        :preferred-countries="preferredCountries"
        :show-code-on-list="showCodeOnList"
        :valid="isValid && !noValidatorState"
        class="input-country-selector"
      >
        <slot
          slot="arrow"
          name="arrow"
        />
      </CountrySelector>
    </div>
    <div>
      <InputTel
        :id="`${uniqueId}_phone_number`"
        ref="PhoneNumberInput"
        v-model="phoneNumber"
        :disabled="disabled"
        :error="error"
        :hint="hintValue"
        :label="t.phoneNumberLabel"
        :no-country-selector="noCountrySelector"
        :required="required"
        :valid="isValid && !noValidatorState"
        v-bind="$attrs"
        @blur="$emit('phone-number-blur')"
        @focus="$emit('phone-number-focused')"
        @keydown="(e) => { lastKeyPressed = e.keyCode }"
      />
    </div>
  </div>
</template>
<script>
import {countries, countriesIso} from './assets/js/phoneCodeCountries.js';
import examples from 'libphonenumber-js/examples.mobile.json';
import {AsYouType, getExampleNumber, parsePhoneNumberFromString} from 'libphonenumber-js';
import InputTel from './InputTel';
import CountrySelector from './CountrySelector';
import locales from './assets/locales';

const browserLocale = () => {
  if (!window) return null;
  const browserLocale = window.navigator.userLanguage || window.navigator.language;
  let locale = browserLocale ? browserLocale.substr(3, 4).toUpperCase() : null;
  if (locale === '') locale = browserLocale.substr(0, 2).toUpperCase();
  return locale;
};

const isCountryAvailable = (locale) => {
  return countriesIso.includes(locale);
};

export default {
  name: 'VuePhoneNumberInput',
  components: {
    InputTel,
    CountrySelector
  },
  props: {
    value: {type: String, default: null},
    id: {type: String, default: 'phoneNumberInput'},
    disabled: {type: Boolean, default: false},
    defaultCountryCode: {type: String, default: null},
    preferredCountries: {type: Array, default: null},
    onlyCountries: {type: Array, default: null},
    ignoredCountries: {type: Array, default: Array},
    translations: {type: Object, default: null},
    noValidatorState: {type: Boolean, default: false},
    noFlags: {type: Boolean, default: false},
    error: {type: Boolean, default: false},
    noExample: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    countriesHeight: {type: Number, default: 30},
    noUseBrowserLocale: {type: Boolean, default: false},
    noCountrySelector: {type: Boolean, default: false},
    showCodeOnList: {type: Boolean, default: true}
  },
  data() {
    return {
      results: {},
      userLocale: this.defaultCountryCode,
      lastKeyPressed: null
    };
  },
  computed: {
    uniqueId() {
      return `${this.id}-${this._uid}`;
    },
    t() {
      return {
        ...locales,
        ...this.translations
      };
    },
    codesCountries() {
      return countries;
    },
    countryCode: {
      get() {
        return this.userLocale || this.results.countryCode;
      },
      set(newCountry) {
        this.setLocale(newCountry);
        this.$refs.PhoneNumberInput.$el.querySelector('input').focus();
      }
    },
    phoneNumber: {
      get() {
        return this.value;
      },
      set(newPhone) {
        this.emitValues({countryCode: this.countryCode, phoneNumber: newPhone});
      }
    },
    shouldChooseCountry() {
      return !this.countryCode && !!this.phoneNumber;
    },
    phoneFormatted() {
      return this.results.formatInternational;
    },
    isValid() {
      return this.results.isValid;
    },
    phoneNumberExample() {
      const phoneNumber = this.countryCode ? getExampleNumber(this.countryCode, examples) : null;
      return phoneNumber ? phoneNumber.formatNational() : null;
    },
    hasEmptyPhone() {
      return this.phoneNumber === '' || this.phoneNumber === null;
    },
    hintValue() {
      return this.noExample || !this.phoneNumberExample
        ? null
        : this.hasEmptyPhone || this.isValid ? null : `${this.t.example} ${this.phoneNumberExample}`;
    }
  },
  watch: {
    defaultCountryCode(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.setLocale(newValue);
    },
    phoneNumber: {
      handler(newValue, oldValue) {
        // init component (countryCode & phoneNumber) if phone number is provide
        if (newValue && (newValue !== oldValue)) {
          const phoneNumber = parsePhoneNumberFromString(newValue);
          if (phoneNumber) {
            this.emitValues({
              phoneNumber: phoneNumber.nationalNumber,
              countryCode: this.countryCode ? this.countryCode : phoneNumber.country
            });
          }
        }
      },
      immediate: true
    }
  },
  async mounted() {
    try {
      if (this.phoneNumber && this.defaultCountryCode) this.emitValues({
        countryCode: this.defaultCountryCode,
        phoneNumber: this.phoneNumber
      });

      if (this.defaultCountryCode && this.fetchCountry) {
        throw new Error('pni: Do not use "fetch-country" and "default-country-code" options in the same time');
      }

      if (this.defaultCountryCode && this.noUseBrowserLocale) {
        throw new Error('pni: If you use a "default-country-code", do not use "no-use-browser-locale" options');
      }

      if (this.defaultCountryCode) return;

      !this.noUseBrowserLocale ? this.setLocale(browserLocale()) : null;
    } catch (err) {
      throw new Error(err);
    }
  },
  methods: {
    getAsYouTypeFormat(payload) {
      const {countryCode, phoneNumber} = payload;
      const asYouType = new AsYouType(countryCode);
      return phoneNumber ? asYouType.input(phoneNumber) : null;
    },
    getParsePhoneNumberFromString({phoneNumber, countryCode}) {
      const parsing = phoneNumber && countryCode ? parsePhoneNumberFromString(phoneNumber, countryCode) : null;
      return {
        countryCode: countryCode,
        isValid: false,
        ...(phoneNumber && (phoneNumber !== '')
            ? {phoneNumber: phoneNumber}
            : null
        ),
        ...(parsing
            ? {
              countryCallingCode: parsing.countryCallingCode,
              formattedNumber: parsing.number,
              nationalNumber: parsing.nationalNumber,
              isValid: parsing.isValid(),
              type: parsing.getType(),
              formatInternational: parsing.formatInternational(),
              formatNational: parsing.formatNational(),
              uri: parsing.getURI(),
              e164: parsing.format('E.164')
            }
            : null
        )
      };
    },
    emitValues(payload) {
      let asYouType = this.getAsYouTypeFormat(payload);
      const backSpacePressed = this.lastKeyPressed === 8;

      this.$nextTick(() => {
        const lastCharacOfPhoneNumber = this.phoneNumber ? this.phoneNumber.trim().slice(-1) : false;
        if (backSpacePressed && lastCharacOfPhoneNumber && (lastCharacOfPhoneNumber.slice(-1) === ')')) {
          asYouType = this.phoneNumber.slice(0, -2);
          payload.phoneNumber = this.phoneNumber.slice(0, -2);
        }

        this.results = this.getParsePhoneNumberFromString(payload);
        this.$emit('update', this.results);
        this.$emit('input', asYouType);
      });
    },
    setLocale(locale) {
      const countryAvailable = isCountryAvailable(locale);
      if (countryAvailable && locale) {
        this.userLocale = countryAvailable ? locale : null;
        this.emitValues({countryCode: locale, phoneNumber: this.phoneNumber});
      } else if (!countryAvailable && locale) {
        window.console.warn(`The locale ${locale} is not available`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.vue-phone-number-input {
  width: 100%;
  display: flex;

  .select-country-container {
    flex: 0 0 110px;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }
}
</style>
