<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}"
       @click="$refs.cardHolder.focus()">
    <input
      id="cardHolder"
      ref="cardHolder"
      v-card-holder
      :placeholder="$t('FORM.CARD.HOLDER_PLACEHOLDER')"
      :value="inputValue"
      autocomplete="cc-name"
      class="card-input__input brand-secondary brand-primary-focus"
      data-card-field
      maxlength="64"
      minlength="3"
      name="cardHolder"
      required
      style="text-transform: uppercase" type="text"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changeCardHolder($event.target.value)"
    />
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import {cardHolderDirective} from '@/components/form/common/form/directives';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';

@Component({
  directives: {
    'card-holder': cardHolderDirective
  }
})
export default class ReceiverNameInputFormField extends InputFormFieldBase<string> {

  constructor() {
    super();
    this.inputValue = '';
  }

  focusHolderField() {
    (this.$refs.cardHolder as any).focus();
  }

  changeCardHolder(value: string) {
    this.inputValue = value.trim();
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: false
    });
  }

  isInvalid(): boolean {
    return !this.inputValue || this.inputValue.length > 64 || this.inputValue.length < 3 || !this.inputValue.match(/^[a-zA-Z\s.\-']+$/);
  }

  getErrorMessage(): string {
    return 'ERROR.CARD_HOLDER';
  }
}
</script>

<style scoped>

</style>
