<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}">
    <CountrySelector
      :id="'countryCode'"
      ref="CountrySelector"
      v-model="inputValue"
      :countries-height="30"
      :disabled="disabled"
      :error="invalid"
      :ignored-countries="ignoredCountries"
      :items="codesCountries"
      :placeholder="$t('FORM.DATA.COUNTRY_CODE')"
      :no-flags="noFlags"
      :only-countries="onlyCountries"
      :preferred-countries="preferredCountries"
      :valid="!invalid"
      :input="changedCountry"
      :show-country-name="true"
      :show-code-on-list="false"
      class="input-country-selector"
    >
    </CountrySelector>
    <input :name="inputName || 'countryCode'"
           :value="inputValue"
           type="hidden"/>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';
import {countries} from '@/components/common/VuePhoneNumberInput/assets/js/phoneCodeCountries.js';
import CountrySelector from '@/components/common/VuePhoneNumberInput/CountrySelector/index.vue';
import {Prop, Watch} from 'vue-property-decorator';

@Component({
  components: {
    CountrySelector
  }
})
export default class CountryCodeInputFormField extends InputFormFieldBase<string> {
  @Prop()
  readonly countryIsoCode!: string;
  @Prop({default: false})
  disabled?: boolean;
  @Prop({default: null})
  preferredCountries?: [];
  @Prop({default: null})
  onlyCountries?: [];
  @Prop({default: () => []})
  ignoredCountries?: [];
  @Prop({default: false})
  noFlags?: boolean;

  @Prop({default: 'US'})
  readonly defaultCountryCode?: string;

  @Watch('defaultCountryCode')
  onDefaultCountryCodeChanged(code: string) {
    if (!this.inputValue) {
      this.inputValue = code.toLowerCase();
    }
  }

  get codesCountries() {
    return countries;
  }

  constructor() {
    super();
    this.inputValue = this.defaultCountryCode || '';
  }

  changedCountry(event: any) {
    this.inputValue = event;
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: false
    });
  }

  isInvalid(): boolean {
    return !this.inputValue;
  }

  getErrorMessage(): string {
    return 'ERROR.TEXT_INPUT';
  }
}
</script>

<style scoped>

</style>
