import Component from 'vue-class-component';
import Vue from 'vue';
import {Emit, Prop, Watch} from 'vue-property-decorator';

export interface FormFieldEvent<TValue> {
  value: TValue,
  invalid: boolean,
  canFocusNextInput: boolean
}

@Component
export default class InputFormFieldBase<TValue> extends Vue {
  readonly isFieldInputComponent = true;
  invalid = false;
  error = '';
  touched = false;
  @Prop()
  inputName!: string;
  @Prop()
  readonly value!: TValue;
  inputValue!: TValue;


  @Watch('value')
  onValueChanged(val: TValue, oldVal: TValue) {
    this.inputValue = val;
    if (this.touched) {
      this.validate();
    }
  }


  @Emit('changed')
  changed(value: FormFieldEvent<TValue>) {
    return value;
  }

  @Emit('focusin')
  focusin(value: any) {
    return value;
  }

  @Emit('focusout')
  focusout(value: any) {
    return value;
  }

  markAsTouched() {
    this.touched = true;
  }

  isInvalid(): boolean {
    return false;
  }

  getErrorMessage(): string {
    return '';
  }

  validate(): void {
    this.invalid = this.isInvalid();
    this.error = this.invalid ? this.getErrorMessage() : '';
  }
}
