import {
  ContainerType,
  FormDataContainer,
  ParameterContainer,
  PathVariableContainer,
  RequestBodyContainer,
  RequestMethod,
  RequestParamContainer,
  RequestType,
  ResponceType
} from './containers';

export function WebApiMapping(path: string) {
  return function (target: Function) {
    // save a reference to the original constructor
    const original = target;

    // a utility function to generate instances of a class
    function construct(constructor: any, args: any) {
      const c: any = function () {
        // @ts-ignore
        return constructor.apply(this, args);
      };
      c.prototype = constructor.prototype;
      const r = new c();
      r.basePath = path;
      return r;
    }

    // the new constructor behaviour
    const f: any = function (args: any) {
      // eslint-disable-next-line prefer-rest-params
      return construct(original, arguments);
    };

    // copy prototype so intanceof operator still works
    f.prototype = original.prototype;

    // return new constructor (will override original)
    return f;
  };
}

export function NoAuth(target: Object, // The prototype of the class
                       propertyKey: string, // The name of the method
                       descriptor: TypedPropertyDescriptor<any>) {
  const metadataKey = `_${propertyKey}_noauth`;
  // @ts-ignore
  if (target[metadataKey]) {
    throw new Error(`Multiple request type decorators on method: ${propertyKey}`);
  } else {
    // @ts-ignore
    target[metadataKey] = true;
  }
  return descriptor;

}

export function FormRequest(target: Object, // The prototype of the class
                            propertyKey: string, // The name of the method
                            descriptor: TypedPropertyDescriptor<any>) {
  const metadataKey = `_${propertyKey}_request`;
  // @ts-ignore
  if (target[metadataKey]) {
    throw new Error(`Multiple request type decorators on method: ${propertyKey}`);
  } else {
    // @ts-ignore
    target[metadataKey] = RequestType.Form;
  }
  return descriptor;

}

export function ResponseBody(target: Object, // The prototype of the class
                             propertyKey: string, // The name of the method
                             descriptor: TypedPropertyDescriptor<any>) {
  const metadataKey = `_${propertyKey}_body`;
  // @ts-ignore
  if (target[metadataKey]) {
    throw new Error(`Multiple responce type decorators on method: ${propertyKey}`);
  } else {
    // @ts-ignore
    target[metadataKey] = ResponceType.Json;
  }
  return descriptor;

}

export function Blob(target: Object, // The prototype of the class
                     propertyKey: string, // The name of the method
                     descriptor: TypedPropertyDescriptor<any>) {
  const metadataKey = `_${propertyKey}_body`;
  // @ts-ignore
  if (target[metadataKey]) {
    throw new Error(`Multiple responce type decorators on method: ${propertyKey}`);
  } else {
    // @ts-ignore
    target[metadataKey] = ResponceType.Blob;
  }
  return descriptor;

}

export function RequestMapping(path: string, method: RequestMethod) {
  return function (target: Object, // The prototype of the class
                   propertyKey: string, // The name of the method
                   descriptor: TypedPropertyDescriptor<any>) {
    const originalMethod = descriptor.value; // save a reference to the original method
    const parametersMetadataKey = `_${propertyKey}_parameters`;
    const bodyMetadataKey = `_${propertyKey}_body`;
    const requestMetadataKey = `_${propertyKey}_request`;
    const noauthMetadataKey = `_${propertyKey}_noauth`;
    const noLoadBroadcastingMetadataKey = `_${propertyKey}_noLoadBroadcasting`;
    // NOTE: Do not use arrow syntax here. Use a function expression in
    // order to use the correct value of `this` in this method (see notes below)
    descriptor.value = function (...args: any[]) {
      const newArgs: any[] = [];
      newArgs[0] = path;
      newArgs[1] = method;
      const containers: ParameterContainer[] = [];
      // @ts-ignore
      if (Array.isArray(target[parametersMetadataKey])) {
        // @ts-ignore
        const metas: ParameterMetadata[] = target[parametersMetadataKey];
        for (let i = 0; i < args.length; i++) {
          const parameterMetadata: ParameterMetadata = metas.filter(function (meta) {
            return meta.index == i;
          })[0];
          if (parameterMetadata) {
            let container: ParameterContainer;
            switch (parameterMetadata.type) {
              case ContainerType.PathVariable:
                // @ts-ignore
                container = new PathVariableContainer(args[i], parameterMetadata.name);
                break;
              case ContainerType.RequestParam:
                // @ts-ignore
                container = new RequestParamContainer(args[i], parameterMetadata.name, parameterMetadata.required);
                break;
              case ContainerType.RequestBody:
                container = new RequestBodyContainer(args[i]);
                break;
              case ContainerType.FormData:
                container = new FormDataContainer(args[i]);
                break;
            }
            containers.push(container);
          }
        }
      }
      newArgs[2] = containers;
      // @ts-ignore
      newArgs[3] = target[bodyMetadataKey];
      // @ts-ignore
      newArgs[4] = target[requestMetadataKey];
      // @ts-ignore
      newArgs[5] = target[noauthMetadataKey];
      // @ts-ignore
      newArgs[6] = target[noLoadBroadcastingMetadataKey];
      return originalMethod.apply(this, newArgs); // return the result of the original method
    };

    return descriptor;
  };
}

export interface ParameterMetadata {
  index: number;
  type: ContainerType;
  name?: string;
  required?: boolean;
}

export function PathVariable(name: string) {
  return function (target: Object, // The prototype of the class
                   propertyKey: string | symbol, // The name of the method
                   parameterIndex: number // The index of parameter in the list of the function's parameters
  ) {
    const metadataKey = `_${propertyKey.toString()}_parameters`;
    const meta: ParameterMetadata = {index: parameterIndex, type: ContainerType.PathVariable, name: name};
    // @ts-ignore
    if (Array.isArray(target[metadataKey])) {
      // @ts-ignore
      target[metadataKey].push(meta);
    } else {
      // @ts-ignore
      target[metadataKey] = [meta];
    }
  };
}

export function RequestParam(name: string, required: boolean) {
  return function (target: Object, // The prototype of the class
                   propertyKey: string | symbol, // The name of the method
                   parameterIndex: number // The index of parameter in the list of the function's parameters
  ) {
    const metadataKey = `_${propertyKey.toString()}_parameters`;
    const meta: ParameterMetadata = {
      index: parameterIndex,
      type: ContainerType.RequestParam,
      name: name,
      required: required
    };
    // @ts-ignore
    if (Array.isArray(target[metadataKey])) {
      // @ts-ignore
      target[metadataKey].push(meta);
    } else {
      // @ts-ignore
      target[metadataKey] = [meta];
    }
  };
}

export function RequestBody(target: Object, // The prototype of the class
                            propertyKey: string | symbol, // The name of the method
                            parameterIndex: number // The index of parameter in the list of the function's parameters
) {
  const metadataKey = `_${propertyKey.toString()}_parameters`;
  const meta: ParameterMetadata = {index: parameterIndex, type: ContainerType.RequestBody};
  // @ts-ignore
  if (Array.isArray(target[metadataKey])) {
    // @ts-ignore
    target[metadataKey].push(meta);
  } else {
    // @ts-ignore
    target[metadataKey] = [meta];
  }
}

export function RequestFormData(target: Object, // The prototype of the class
                                propertyKey: string | symbol, // The name of the method
                                parameterIndex: number // The index of parameter in the list of the function's parameters
) {
  const metadataKey = `_${propertyKey.toString()}_parameters`;
  const meta: ParameterMetadata = {index: parameterIndex, type: ContainerType.FormData};
  // @ts-ignore
  if (Array.isArray(target[metadataKey])) {
    // @ts-ignore
    target[metadataKey].push(meta);
  } else {
    // @ts-ignore
    target[metadataKey] = [meta];
  }
}

export function CustomInjectable() {
  return function (target: Function) {
    const parentTarget = Object.getPrototypeOf(target.prototype).constructor;
    const parentParamTypes = (<any>Reflect).getMetadata('design:paramtypes', parentTarget);
    const parentParameters = (<any>Reflect).getMetadata('parameters', parentTarget);

    (<any>Reflect).defineMetadata('design:paramtypes', parentParamTypes, target);
    (<any>Reflect).defineMetadata('parameters', parentParameters, target);
  };
}

export function NoLoadBroadcasting(
  target: Object, // The prototype of the class
  propertyKey: string, // The name of the method
  descriptor: TypedPropertyDescriptor<any>) {
  const metadataKey = `_${propertyKey}_noLoadBroadcasting`;
  // @ts-ignore
  if (target[metadataKey]) {
    throw new Error(`Multiple request type decorators on method: ${propertyKey}`);
  } else {
    // @ts-ignore
    target[metadataKey] = true;
  }
  return descriptor;
}
