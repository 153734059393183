import Vue from 'vue';

export function Log(message?: any, ...optionalParams: any[]) {
  if (Vue.config.devtools) {
    const timestamp = `[${((new Date).getTime() - window.performance.timing.domLoading) / 1000}s] `;
    if (console) {
      if (optionalParams.length > 0) {
        console.log(timestamp, message, optionalParams);
      } else {
        console.log(timestamp, message);
      }
    }
  }
  return;
}


export function Warning(message?: any, ...optionalParams: any[]) {
  if (Vue.config.devtools) {
    const timestamp = `[${((new Date).getTime() - window.performance.timing.domLoading) / 1000}s] `;
    if (console) {
      if (optionalParams.length > 0) {
        console.error(timestamp, message, optionalParams);
      } else {
        console.error(timestamp, message);
      }
    }
  }
  return;
}
