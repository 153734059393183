import Component from './Component.vue';
import eventBus from './bus';

const Api = (Vue: any, globalOptions = {}) => {
  return {
    open(options: any) {
      let message;
      if (typeof options === 'string') message = options;

      const defaultOptions = {
        message
      };

      const propsData = Object.assign({}, defaultOptions, globalOptions, options);

      return new (Vue.extend(Component))({
        el: document.createElement('div'),
        propsData
      });
    },
    clear() {
      eventBus.emit('toast-clear');
    },
    success(message: any, options = {}) {
      return this.open(Object.assign({}, {
        message,
        type: 'success'
      }, options));
    },
    error(message: any, options = {}) {
      return this.open(Object.assign({}, {
        message,
        type: 'error'
      }, options));
    },
    info(message: any, options = {}) {
      return this.open(Object.assign({}, {
        message,
        type: 'info'
      }, options));
    },
    warning(message: any, options = {}) {
      return this.open(Object.assign({}, {
        message,
        type: 'warning'
      }, options));
    },
    default(message: any, options = {}) {
      return this.open(Object.assign({}, {
        message,
        type: 'default'
      }, options));
    }
  };
};

export default Api;
