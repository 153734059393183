import {DirectiveOptions} from 'vue/types/options';

export const numberOnlyDirective: DirectiveOptions = {
  bind(el) {
    function checkValue(event: any) {
      event.target.value = event.target.value.replace(/[^0-9\/\s]/g, '');
      if (event.charCode >= 48 && event.charCode <= 57) {
        return true;
      }
      event.preventDefault();
    }

    el.addEventListener('keypress', checkValue);
  }
};
export const letterOnlyDirective: DirectiveOptions = {
  bind(el) {
    function checkValue(event: any) {
      if (event.charCode >= 48 && event.charCode <= 57) {
        event.preventDefault();
      }
      return true;
    }

    el.addEventListener('keypress', checkValue);
  }
};
export const cardHolderDirective: DirectiveOptions = {
  bind(el) {
    function checkValue(event: any) {
      if (event.charCode >= 65 && event.charCode <= 90) {
        return true;
      }

      if (event.charCode >= 97 && event.charCode <= 122) {
        return true;
      }

      if (event.charCode >= 97 && event.charCode <= 122) {
        return true;
      }

      if (event.charCode == 39 || event.charCode == 32 || event.charCode == 45 || event.charCode == 46) {
        return true;
      }
      event.preventDefault();
    }

    el.addEventListener('keypress', checkValue);
  }
};
