<template>
  <div class="footer">
    <div class="footer-poweredby no-select brand-powered-by">
      <PoweredByPaidora></PoweredByPaidora>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Modal from '@/components/common/Modal.vue';
import Component from 'vue-class-component';
import PoweredByPaidora from '@/components/form/common/PoweredByPaidora.vue';

@Component({
  components: {
    Modal,
    PoweredByPaidora
  }
})
export default class ErrorFooter extends Vue {
  showModal = false;
  termsOfServiceHeader?: string = '';
  termsOfServiceText?: string = '';

  showTermsOfService(): void {
    this.termsOfServiceText = '';
    this.termsOfServiceHeader = '';
    this.showModal = true;
  }

  closeTermsOfService(): void {
    this.showModal = false;
    this.termsOfServiceText = '';
    this.termsOfServiceHeader = '';
  }
}
</script>

<style lang="scss" scoped>
.term-of-service {
  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: none;
    outline: none;
  }
}


</style>
