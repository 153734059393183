import { render, staticRenderFns } from "./MobilePhoneInputFormField.vue?vue&type=template&id=ef3c00b0&scoped=true"
import script from "./MobilePhoneInputFormField.vue?vue&type=script&lang=ts"
export * from "./MobilePhoneInputFormField.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef3c00b0",
  null
  
)

export default component.exports