<template>
  <spinner></spinner>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {BillingFormApiService} from '@/services/api/billing/form-billing.api.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {MatomoUtils} from '@/matomo';
import Spinner from '@/components/common/Spinner.vue';
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;
import DataResponse = com.paidora.framework.common.web.models.responses.DataResponse;

@Component(
  {
    components: {
      Spinner
    }
  })
export default class LoadingComponent extends PayStepBase {

  mounted() {
    if (this.formData.token.startsWith('test')) {
      switch (this.formData.token) {
        case 'test_success':
          this.formData.formStatus = 'PROCESSED';
          break;
        case 'test_error':
          this.formData.formStatus = 'FAILED';
          this.formData.formStatusDetails = 'Transaction failed';
          break;
        case 'test_info':
          this.formData.formStatus = 'SUBMITTED';
          break;
      }

      this.formDataChanged(this.formData);
      return;
    }

    let fp = '';
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        fp = result.visitorId;
      });

    if (this.formData.formStatus === 'SUBMITTED') {
      // todo: rewrite on two setTimeout
      let timerId = setInterval(() => {
        new BillingFormApiService().form(this.formData.token as string, {
          referer: document.referrer,
          fp: fp as unknown as string
        }).then((r: DataResponse<PaymentFormInfo>) => {
            if (r.success) {
              if (r.data.formStatus !== 'SUBMITTED') {
                clearInterval(timerId);
                this.formData = r.data;
                this.formDataChanged(r.data);
              }
            } else {
              MatomoUtils.sendFormLoadingFailedInteraction('Failed response', JSON.stringify(r));
            }
          }, (e) => {
            MatomoUtils.sendFormLoadingFailedInteraction('Http failed', e);
          }
        );
      }, 10000);

      setTimeout(() => {
        clearInterval(timerId);
        MatomoUtils.sendFormLoadingFailedInteraction('Loading Expired', '');
        this.formData.formStatus = 'EXPIRED';
        this.$emit('expired');
        return;
      }, 90000);
    } else {
      this.formDataChanged(this.formData);
    }
  }
}
</script>

<style scoped>
</style>
