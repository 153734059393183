import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import {version} from './version';
import i18n from './i18n';
import router from '@/router';
// // @ts-ignore
// import VueMatomo from 'vue-matomo';
import {ToastPlugin} from '@/components/common/Toast';

if (console) {
  console.log(`Version: ${version}`);
}

Vue.config.productionTip = false;
Vue.use(ToastPlugin);

(<any>window).EventBus = new Vue();

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');

export function getLocalUrlBase() {
  return `//${window.location.hostname}${window.location.port && window.location.port != '80' ? ':' + window.location.port : ''}/`;
}

// Vue.use(VueMatomo, {
//   host: getLocalUrlBase() + 'ph',
//   trackerScriptUrl: getLocalUrlBase() + 'pj',
//   trackerUrl: getLocalUrlBase() + 'ph',
//   // @ts-ignore
//   siteId: process.env.VUE_APP_A_SITE_ID || '0',
//   trackerFileName: 'matomo',
//   router: router,
//   enableLinkTracking: true,
//   requireConsent: false,
//   trackInitialView: true,
//   enableHeartBeatTimer: true,
//   trackSiteSearch: true
// });


