<template>
  <div
    ref="parent"
    :class="[{
      'is-focused': isFocus,
      'has-value': value,
      'has-hint': hint,
      'has-error': error,
      'is-disabled': disabled,
      'no-flags': noFlags,
      'has-list-open': hasListOpen,
      'is-valid': valid
    }, size]"
    class="country-selector"
    @mouseenter="updateHoverState(true)"
    @mouseleave="updateHoverState(false)"
    @blur.capture="handleBlur"
  >
    <div
      v-if="value && !noFlags"
      class="country-selector__country-flag"
      @click.stop="toggleList"
    >
      <div :class="`iti-flag-small iti-flag ${value.toLowerCase()}`"/>
    </div>
    <input
      :id="id"
      ref="CountrySelector"
      :placeholder="placeholder"
      :value="callingCode"
      :disabled="disabled"
      class="country-selector__input brand-secondary"
      readonly
      @focus="isFocus = true"
      @keydown="keyboardNav"
      @click.stop="toggleList"
    >
    <div
      class="country-selector__toggle"
      @click.stop="toggleList"
    >
      <slot name="arrow">
        <svg
          class="country-selector__toggle__arrow"
          height="24"
          mlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
        >
          <path
            class="arrow"
            d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          />
          <path
            d="M0 0h24v24H0V0z"
            fill="none"
          />
        </svg>
      </slot>
    </div>
<!--    <label-->
<!--      ref="label"-->
<!--      class="country-selector__label"-->
<!--      @click.stop="toggleList"-->
<!--    >-->
<!--      {{ hint || label }}-->
<!--    </label>-->
    <Transition name="slide">
      <div
        v-show="hasListOpen"
        ref="countriesList"
        :class="{ 'has-calling-code': showCodeOnList }"
        :style="[listHeight]"
        class="country-selector__list brand-primary brand-primary-border"
      >
        <RecycleScroller
          v-slot="{ item }"
          :item-size="1"
          :items="countriesSorted"
          key-field="iso2"
        >
          <button
            :key="`item-${item.code}`"
            :class="[
              { 'brand-secondary': value === item.iso2 },
              { 'selected': value === item.iso2 },
              { 'keyboard-selected': value !== item.iso2 && tmpValue === item.iso2 }
            ]"
            :style="[itemHeight]"
            class="align-center country-selector__list__item brand-primary brand-primary-hover"
            tabindex="-1"
            type="button"
            @click.stop="updateValue(item.iso2)"
          >
            <div
              v-if="!noFlags"
              class="country-selector__list__item__flag-container"
            >
              <div :class="`iti-flag-small iti-flag ${item.iso2.toLowerCase()}`"/>
            </div>
            <div class="dots-text">
              <b>{{ item.name }}</b> <template v-if="showCodeOnList">+{{ item.dialCode }}</template>
            </div>
          </button>
        </RecycleScroller>
      </div>
    </Transition>
  </div>
</template>

<script>
import {getCountryCallingCode} from 'libphonenumber-js';

import {RecycleScroller} from 'vue-virtual-scroller';

export default {
  name: 'CountrySelector',
  components: {
    RecycleScroller
  },
  props: {
    id: {type: String, default: 'CountrySelector'},
    value: {type: [String, Object], default: null},
    label: {type: String, default: 'Choose country'},
    placeholder: {type: String, default: null},
    hint: {type: String, default: String},
    size: {type: String, default: String},
    error: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    valid: {type: Boolean, default: false},
    items: {type: Array, default: Array, required: true},
    preferredCountries: {type: Array, default: null},
    onlyCountries: {type: Array, default: null},
    ignoredCountries: {type: Array, default: null},
    noFlags: {type: Boolean, default: false},
    countriesHeight: {type: Number, default: 35},
    showCodeOnList: {type: Boolean, default: true},
    showCountryName: {type: Boolean, default: false}
  },
  data() {
    return {
      isFocus: false,
      hasListOpen: false,
      selectedIndex: null,
      tmpValue: this.value,
      query: '',
      indexItemToShow: 0,
      isHover: false
    };
  },
  computed: {
    itemHeight() {
      return {
        height: `${this.countriesHeight}px`
      };
    },
    listHeight() {
      return {
        height: `${(this.countriesHeight + 1) * 7}px`,
        maxHeight: `${(this.countriesHeight + 1) * 7}px`
      };
    },
    countriesList() {
      return this.items.filter(item => !this.ignoredCountries.includes(item.iso2));
    },
    countriesFiltered() {
      const countries = this.onlyCountries || this.preferredCountries;
      return countries.map(country => this.countriesList.find(item => item.iso2.includes(country)));
    },
    otherCountries() {
      return this.countriesList.filter(item => !this.preferredCountries.includes(item.iso2));
    },
    countriesSorted() {
      return this.preferredCountries
        ? [...this.countriesFiltered,
          ...this.otherCountries]
        : this.onlyCountries
          ? this.countriesFiltered
          : this.countriesList;
    },
    selectedValueIndex() {
      return this.value
        ? this.countriesSorted.findIndex(c => c.iso2 === this.value)
        : null;
    },
    tmpValueIndex() {
      return this.countriesSorted.findIndex(c => c.iso2 === this.tmpValue);
    },
    callingCode() {
      return this.value ? (this.showCountryName ? this.countriesList.filter(c => c.iso2 == this.value)[0].name : `+${getCountryCallingCode(this.value)}`) : null;
    }
  },
  methods: {
    updateHoverState(value) {
      this.isHover = value;
    },
    handleBlur(e) {
      if (this.$el.contains(e.relatedTarget)) return;
      this.isFocus = false;
      this.closeList();
    },
    toggleList() {
      this.$refs.countriesList.offsetParent ? this.closeList() : this.openList();
    },
    openList() {
      if (!this.disabled) {
        this.$refs.CountrySelector.focus();
        this.$emit('open');
        this.isFocus = true;
        this.hasListOpen = true;
        if (this.value) this.scrollToSelectedOnFocus(this.selectedValueIndex);
      }
    },
    closeList() {
      this.$emit('close');
      this.hasListOpen = false;
    },
    async updateValue(val) {
      this.tmpValue = val;
      this.$emit('input', val || null);
      await this.$nextTick();
      this.closeList();
    },
    scrollToSelectedOnFocus(arrayIndex) {
      this.$nextTick(() => {
        // this.indexItemToShow = arrayIndex - 3
        this.$refs.countriesList.scrollTop = arrayIndex * (this.countriesHeight + 1) - ((this.countriesHeight + 1) * 3);
      });
    },
    keyboardNav(e) {
      const code = e.keyCode;
      if (code === 40 || code === 38) {
        // arrow up down
        if (e.view && e.view.event) {
          // TODO : It's not compatible with FireFox
          e.view.event.preventDefault();
        }
        if (!this.hasListOpen) this.openList();
        let index = code === 40 ? this.tmpValueIndex + 1 : this.tmpValueIndex - 1;
        if (index === -1 || index >= this.countriesSorted.length) {
          index = index === -1
            ? this.countriesSorted.length - 1
            : 0;
        }
        this.tmpValue = this.countriesSorted[index].iso2;
        this.scrollToSelectedOnFocus(index);
      } else if (code === 13) {
        // enter
        this.hasListOpen ? this.updateValue(this.tmpValue) : this.openList();
      } else if (code === 27) {
        // escape
        this.closeList();
      } else {
        // typing a country's name
        this.searching(e);
      }
    },
    searching(e) {
      const code = e.keyCode;
      clearTimeout(this.queryTimer);
      this.queryTimer = setTimeout(() => {
        this.query = '';
      }, 1000);
      const q = String.fromCharCode(code);
      if (code === 8 && this.query !== '') {
        this.query = this.query.substring(0, this.query.length - 1);
      } else if (/[a-zA-Z-e\\p{L} ]/.test(q)) {
        if (!this.hasListOpen) this.openList();
        this.query += e.key;
        const countries = this.preferredCountries ? this.countriesSorted.slice(this.preferredCountries.length) : this.countriesSorted;
        let resultIndex = countries.findIndex(c => {
          this.tmpValue = c.iso2;
          return c.name.toLowerCase().startsWith(this.query);
        });
        if (resultIndex === -1) {
          resultIndex = countries.findIndex(c => {
            this.tmpValue = c.iso2;
            return c.name.toLowerCase().indexOf('(' + this.query) > -1;
          });
        }
        if (resultIndex !== -1) {
          this.scrollToSelectedOnFocus(resultIndex + (this.preferredCountries ? this.preferredCountries.length : 0));
        } else {
          this.query = '';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './assets/iti-flags/flags.css';


// Light Theme
.country-selector {
  font-family: Montserrat-Medium, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  position: relative;
  height: 44px;
  min-height: 44px;
  z-index: 0;
  user-select: none;
  width: 100%;

  &:hover {
    z-index: 1;
  }

  &__label {
    position: absolute;
    top: 3px;
    cursor: pointer;
    left: 11px;
    transform: translateY(25%);
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 11px;
    //color: $secondary-color;
  }

  &__input {
    cursor: pointer;
    //background-color: #F7F7F7;
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    padding-right: 22px;
    font-weight: 400;
    appearance: none;
    outline: none;
    border: none;
    font-size: 13px;
    z-index: 0;
    padding-left: 8px;
    //color: $text-color;
    //border-radius: 17px;
    font-family: Montserrat-Medium, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  }

  &__toggle {
    position: absolute;
    right: 5px;
    top: calc(50% - 12px);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    display: inline-block;
    cursor: pointer;
    height: 24px;

    &__arrow {
      //color: $secondary-color;

      path.arrow {
        //fill: $secondary-color;
      }
    }
  }

  &__country-flag {
    margin: auto 0;
    position: absolute;
    top: calc(50% - 5px);
    left: 11px;
    z-index: 1;
    cursor: pointer;

    img {
      position: absolute;
    }
  }

  &__list {
    max-width: 100%;
    top: 100%;
    width: 100%;
    min-width: 230px;
    position: absolute;
    //background-color: $bg-color;
    overflow: hidden;
    //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index: 9;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    //border-radius: 12px;
    font-family: Montserrat-Medium, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    &.has-calling-code {
      min-width: 270px;
    }

    &__item {
      padding: 0 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      cursor: pointer;
      //background-color: inherit;
      width: 100%;
      border: 0;
      outline: none;
      display: flex;
      align-items: center;

      &__flag-container {
        margin-right: 10px;
      }

      &__calling-code {
        width: 45px;
        //color: $muted-color;
      }

      &.hover,
      &.keyboard-selected {
        //background-color: $hover-color;
      }

      &.selected {
        //color: #FFF;
        //font-weight: 600;

        .country-selector__list__item__calling-code {
          //color: #FFF;
        }
      }
    }
  }

  &.has-list-open {
    z-index: 1;

    .country-selector {
      &__toggle {
        transform: rotate(180deg);
      }
    }
  }

  &.is-focused {
    z-index: 1;
  }

  &.has-error {
    .country-selector__input {
      //border-color: $danger-color;
    }

    .country-selector__label {
      //color: $danger-color;
    }
  }

  &.has-value {
    .country-selector__input {
      padding-left: 40px;
    }
  }

  &.has-value,
  &.has-hint {
    .country-selector__label {
      opacity: 1;
      transform: translateY(0);
      font-size: 11px;
    }

    .country-selector__input {
      //padding-top: 18px;
    }
  }

  // Disable theme
  &.is-disabled {
    .country-selector {
      cursor: not-allowed;

      //&__input {
      //  border-color: #CCC;
      //  background-color: #F2F2F2;
      //  //color: $disabled-color;
      //  //
      //  //&::-webkit-input-placeholder {
      //  //  color: $disabled-color;
      //  //}
      //  //
      //  //&::-moz-placeholder {
      //  //  color: $disabled-color;
      //  //}
      //  //
      //  //&:-ms-input-placeholder {
      //  //  color: $disabled-color;
      //  //}
      //  //
      //  //&::-ms-input-placeholder {
      //  //  color: $disabled-color;
      //  //}
      //  //
      //  //&:-moz-placeholder {
      //  //  color: $disabled-color;
      //  //}
      //  //
      //  //&::placeholder {
      //  //  color: $disabled-color;
      //  //}
      //}

      &__label,
      &__input,
      &__toggle__arrow,
      &__country-flag,
      &__country-flag > div {
        cursor: not-allowed;
        //color: $disabled-color;
      }
    }
  }

  &.no-flags {
    .country-selector__input {
      padding-left: 10px;
    }
  }

  .country-selector__input {
    height: 44px;
    min-height: 44px;
    font-size: 18px;
  }

  .country-selector__label {
    font-size: 12px;
  }

  .country-selector__country-flag {

    img {
      zoom: 0.45;
      /* stylelint-disable */
      -moz-transform: scale(0.45);
      -moz-transform-origin: 0 0;
      /* stylelint-enable */
    }
  }

  &.has-value {
    .country-selector__input {
      //padding-top: 18px;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    opacity: 1;
    z-index: 998;
    transition: all 0.3s;
    transform: translateY(0);
  }

  .slide-enter,
  .slide-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    z-index: 998;
    transform: translateY(-20px);
  }
}
</style>
