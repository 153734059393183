<template>
  <div class="main-info brand-header brand-iframe brand-header-border-radius">
    <!--    <Modal v-if="showModal" @close="showModal=false"></Modal>-->
    <div class="main-info-header-merchant brand-secondary-bb">
      <div v-if="!isIFrame && this.backUrl" class="main-info-header-merchant-back-button">
        <button type="button" v-on:click="navBack()">
          <svg fill="none" height="16" viewBox="0 0 9 16" width="9" xmlns="http://www.w3.org/2000/svg">
            <path class="brand-header" clip-rule="evenodd" d="M8.62342 0.390524C9.12553 0.911223 9.12553 1.75544 8.62342 2.27614L3.10399 8L8.62342 13.7239C9.12553 14.2446 9.12553 15.0888 8.62342 15.6095C8.12132 16.1302 7.30725 16.1302 6.80515 15.6095L0.376577 8.94281C-0.125526 8.42211 -0.125526 7.57789 0.376577 7.05719L6.80515 0.390524C7.30725 -0.130175 8.12132 -0.130175 8.62342 0.390524Z" fill-rule="evenodd"/>
          </svg>
        </button>
      </div>
      <div v-if="brand.merchantLogo" class="main-info-header-merchant-image">
        <img :src="brand.merchantLogo" alt="" class="main-info-header-merchant-image-img">
      </div>
      <div v-if="brand.merchantName" class="main-info-header-merchant-name"> {{ brand.merchantName }}</div>
    </div>
    <div class="main-info-body no-select brand-header-bottom">
      <div class="main-info-body-price-and-details">
        <div v-if="amount" class="main-info-body-price-and-details-price" v-html="amountFormatted">
        </div>
        <!--        <div v-if="false" class="main-info-body-price-and-details-details">-->
        <!--          <button id="details" @click="showModal = true">Детали заказа</button>-->
        <!--        </div>-->
      </div>
      <div v-if="description" class="main-info-body-label" style="word-break: break-all;"> {{ description }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import Modal from '@/components/common/Modal.vue';
import {MatomoUtils} from '@/matomo';
import {currencies} from '@/utils/currencies';
import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;

@Component({
  components: {
    Modal
  }
})
export default class FormHeader extends Vue {
  @Prop()
  brand!: FormBrandInfo;
  @Prop()
  orderId!: string;
  @Prop()
  description!: string;
  @Prop()
  currency!: string;
  @Prop()
  currencyScale!: number;
  @Prop()
  amount!: number;
  @Prop()
  method!: string;
  @Prop()
  returnUrl!: string;
  @Prop()
  isIFrame!: boolean;
  @Prop()
  backUrl!: string;

  get amountFormatted() {
    var symbol = currencies[this.currency];
    var formatted = Intl.NumberFormat(this.$i18n.locale, {
      style: 'currency',
      currency: this.currency == 'USDT' ? 'USD' : this.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: this.currencyScale != null ? this.currencyScale : 2
    }).format(this.amount);
    if (symbol) {
      formatted = formatted.replace(this.currency, symbol);
    }
    if (this.currency == 'USDT') {
      formatted = formatted.replaceAll(/[^\d.,-]/g, '') + ' USDT';
    }
    return formatted;
  }

  navBack() {
    MatomoUtils.sendRedirectInteraction('backUrl', 'header navigation back button');
    document.location.href = this.backUrl as unknown as string;
  }
}
</script>

<style scoped>

</style>
