<template>
  <FormContent>
    <Modal v-if="showModal" v-bind:body="modalText" v-bind:header="modalHeader" @close="closeModalWindow()"></Modal>
    <FormField>
      <CardInputFormField
        ref="cardPanField"
        :value="formFields.cardPan"
        @changed="changePan($event)"
        @focusin="sendEventToFocusIn($event)"
        @focusout="sendEventToFocusOut($event)"
      ></CardInputFormField>
    </FormField>
    <div class="hidden-fields" v-bind:class="{'show-fields': isShowFields}">
      <FormRow>
        <FormCol>
          <FormField>
            <CardExpirationInputFormField
              ref="cardExpField"
              :value="{ month: formFields.cardMonth, year: formFields.cardYear}"
              @changed="changeExp($event)"
              @focusin="sendEventToFocusIn($event)"
              @focusout="sendEventToFocusOut($event)"
            >
            </CardExpirationInputFormField>
          </FormField>
        </FormCol>
        <FormCol>
          <FormField>
            <CardCvvInputFormField
              ref="cardCvvField"
              :input-name="'cvc'"
              :value="formFields.cardCvv"
              @changed="changeCvv($event)"
              @focusin="sendEventToFocusIn($event)"
              @focusout="sendEventToFocusOut($event)"
            >
            </CardCvvInputFormField>
          </FormField>
        </FormCol>
      </FormRow>
      <FormField>
        <CardHolderInputFormField
          ref="cardHolderField"
          :value="formFields.cardHolder"
          @changed="changeCardHolder($event)"
          @focusin="sendEventToFocusIn($event)"
          @focusout="sendEventToFocusOut($event)"
        ></CardHolderInputFormField>
      </FormField>
    </div>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import {cardHolderDirective, letterOnlyDirective, numberOnlyDirective} from '@/components/form/common/form/directives';
import FormField from '@/components/form/common/form/FormField.vue';
import CardPanInputFormField from '@/components/form/common/form/fields/CardPanInputFormField.vue';
import CardExpirationInputFormField from '@/components/form/common/form/fields/CardExpirationInputFormField.vue';
import CardCvvInputFormField from '@/components/form/common/form/fields/CardCvvInputFormField.vue';
import CardHolderInputFormField from '@/components/form/common/form/fields/CardHolderInputFormField.vue';
import {FormFieldEvent} from '@/components/form/common/form/fields/InputFormFieldBase';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';

@Component(
  {
    components: {
      CardHolderInputFormField,
      CardInputFormField: CardPanInputFormField,
      CardExpirationInputFormField,
      CardCvvInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {
      'number-only': numberOnlyDirective,
      'letter-only': letterOnlyDirective,
      'card-holder': cardHolderDirective
    }
  })
export default class CardMethodForm extends DataFormBase implements IForm {
  showModal = false;
  isShowFields = false;
  modalText?: string;
  modalHeader?: string | null;

  constructor() {
    super();

    if (!this.formData.flags || this.formData.flags.length === 0) {
      this.isShowFields = true;
    }

    this.formFields = {
      cardHolder: '',
      cardPan: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      cardEmail: ''
    };
  }

  changePan(e: FormFieldEvent<string>) {
    this.formFields.cardPan = e.value;
    if (this.formData.flags) {
      this.isShowFields = !!e.value;
    }

    if (e.canFocusNextInput) {
      this.focusExpirationField();
    }
    this.validate();
  }

  changeExp(e: FormFieldEvent<{ year: string, month: string }>) {
    this.formFields.cardMonth = e.value.month;
    this.formFields.cardYear = e.value.year;
    if (e.canFocusNextInput) {
      this.focusCvvField();
    }
    this.validate();
  }

  changeCvv(e: FormFieldEvent<string>) {
    this.formFields.cardCvv = e.value;
  }

  changeCardHolder(e: FormFieldEvent<string>) {
    this.formFields.cardHolder = e.value;
    this.validate();
  }

  showModalWindow(text: string): void {
    this.modalText = text;
    this.modalHeader = null;
    this.showModal = true;
  }

  closeModalWindow(): void {
    this.showModal = false;
    this.modalHeader = '';
    this.modalText = '';
  }

  private focusExpirationField() {
    (this.$refs.cardExpField as CardExpirationInputFormField).focusExpirationField();
  }

  private focusCvvField() {
    (this.$refs.cardCvvField as CardCvvInputFormField).focusCvvField();
  }
}
</script>

<style lang="scss" scoped>
.hidden-fields {
  opacity: 0;
  max-height: 0;
  transition: all 1s;
}

.show-fields {
  opacity: 1;
  transition: all 1s;
  max-height: 400px;
}
</style>
