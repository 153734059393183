<template>
  <div style="flex: 1;">
    <div class="panel-body">
      <Modal v-if="isModalForSupportCanBeShown" v-bind:body="modalText" v-bind:header="modalHeader"
             @close="closeModalWindow()"></Modal>
      <div class="status-label no-select">{{ $t(statusText) }}
        <span v-if="additionalInfoStatusText" class="status-additional no-select"><br>{{ $t(additionalInfoStatusText) }}</span>
        <span v-if="showModal"> {{ $t('FORM.SUPPORT.DETAILS') }}</span></div>
      <div class="status-bill">
        <svg v-if="this.formStatus === 'PROCESSED'" fill="none" height="44" viewBox="0 0 44 44" width="44"
             xmlns="http://www.w3.org/2000/svg">
          <path class="brand-accent--invert"
                d="M22 0C17.6488 0 13.3953 1.29028 9.77746 3.70767C6.15958 6.12506 3.33979 9.56099 1.67466 13.581C0.00953223 17.6009 -0.426141 22.0244 0.422734 26.292C1.27161 30.5596 3.3669 34.4796 6.44366 37.5563C9.52041 40.6331 13.4404 42.7284 17.708 43.5773C21.9756 44.4261 26.3991 43.9905 30.419 42.3253C34.439 40.6602 37.8749 37.8404 40.2923 34.2225C42.7097 30.6047 44 26.3512 44 22C44 16.1652 41.6822 10.5694 37.5564 6.44365C33.4305 2.31785 27.8348 0 22 0ZM18.8571 30.7843L11 22.9271L13.4986 20.4286L18.8571 25.7871L30.5014 14.1429L33.0094 16.6351L18.8571 30.7843Z"/>
        </svg>
        <svg v-else-if="this.formStatus === 'FAILED'" fill="none" height="34" viewBox="0 0 34 34" width="34"
             xmlns="http://www.w3.org/2000/svg">
          <path class="brand-error" clip-rule="evenodd"
                d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM15.0365 8.84006C15.0365 8.29884 15.2447 7.78005 15.6154 7.39738C15.9861 7.01499 16.4889 6.8 17.0131 6.8C17.1617 6.8 17.3087 6.81715 17.4513 6.85063C17.8118 6.9353 18.1452 7.12345 18.411 7.39738C18.5821 7.57391 18.7185 7.77949 18.8159 8.00306C18.9295 8.2637 18.99 8.5487 18.99 8.84006V18.2105C18.99 18.4784 18.9388 18.7434 18.8395 18.9911C18.7401 19.2384 18.5944 19.4634 18.411 19.6529C18.2274 19.8425 18.0095 19.9927 17.7696 20.0951C17.5299 20.1977 17.2728 20.2503 17.0131 20.2503C16.889 20.2503 16.7656 20.2381 16.6443 20.2143C16.5118 20.1886 16.3819 20.1488 16.2567 20.0951C16.0169 19.9927 15.799 19.8425 15.6154 19.6529C15.5747 19.6109 15.5358 19.5671 15.4989 19.5217C15.472 19.4885 15.446 19.4545 15.4212 19.4197C15.3268 19.2874 15.2481 19.1435 15.187 18.9911C15.0875 18.7434 15.0365 18.4784 15.0365 18.2105V8.84006ZM17 27.2C16.5518 27.2 16.1135 27.0628 15.7408 26.8057C15.3679 26.5487 15.0774 26.1834 14.9059 25.7559C14.8552 25.6298 14.8156 25.5 14.7873 25.368C14.7194 25.0526 14.7152 24.7244 14.7769 24.4046C14.8643 23.9508 15.0802 23.5338 15.3973 23.2068C15.7142 22.8797 16.118 22.6567 16.5578 22.5665C16.9975 22.4763 17.4532 22.5225 17.8674 22.6996C18.2816 22.8767 18.6357 23.1766 18.8847 23.5612C19.1337 23.9458 19.2667 24.3982 19.2667 24.8608C19.2667 25.4812 19.0279 26.0761 18.6027 26.5149C18.1777 26.9535 17.6011 27.2 17 27.2Z"
                fill-rule="evenodd"/>
        </svg>
        <svg v-else fill="none" height="34" viewBox="0 0 34 34" width="34" xmlns="http://www.w3.org/2000/svg">
          <path class="brand-primary" clip-rule="evenodd"
                d="M34 17C34 26.3889 26.3888 34 17 34C7.61121 34 0 26.3889 0 17C0 7.61108 7.61121 0 17 0C26.3888 0 34 7.61108 34 17ZM16.0858 24.6653C16.4608 24.2988 16.9696 24.093 17.5 24.093C18.0304 24.093 18.5392 24.2988 18.9142 24.6653C19.129 24.875 19.29 25.1277 19.3883 25.4026C19.4615 25.6077 19.5 25.8252 19.5 26.0466C19.5 26.5647 19.2893 27.0615 18.9142 27.4277C18.5392 27.7942 18.0304 28 17.5 28C16.9696 28 16.4608 27.7942 16.0858 27.4277C15.7107 27.0615 15.5 26.5647 15.5 26.0466C15.5 25.5283 15.7107 25.0315 16.0858 24.6653ZM13.611 8.57349C14.6423 7.56592 16.0413 7 17.5 7C18.9587 7 20.3577 7.56592 21.389 8.57349C22.4205 9.58081 23 10.9473 23 12.3721C23 14.4878 22.316 15.5994 20.5601 17.3145L20.29 17.584C19.27 18.6135 19 19.1331 19 20.186C19 20.5747 18.8419 20.9473 18.5607 21.2219C18.2793 21.4968 17.8978 21.6511 17.5 21.6511C17.1022 21.6511 16.7207 21.4968 16.4393 21.2219C16.1581 20.9473 16 20.5747 16 20.186C16 18.0703 16.684 16.959 18.4399 15.2437L18.71 14.9741C19.73 13.9446 20 13.425 20 12.3721C20 11.7244 19.7366 11.1033 19.2678 10.6455C18.799 10.1875 18.1631 9.93018 17.5 9.93018C16.8369 9.93018 16.201 10.1875 15.7322 10.6455C15.2634 11.1033 15 11.7244 15 12.3721L14.986 12.5713C14.9342 12.9392 14.7415 13.2739 14.4467 13.5083C14.1517 13.7424 13.777 13.8586 13.3977 13.8333C13.0186 13.8081 12.6635 13.6431 12.4039 13.3718C12.1445 13.1006 12.0001 12.7434 12 12.3721C12 10.9473 12.5795 9.58081 13.611 8.57349Z"
                fill-rule="evenodd"/>
        </svg>
      </div>
      <div class="panel-footer">
        <div class="panel-footer-submit">
          <button v-if="false" class="button-black brand-button-border-radius"
                  type="button">
            {{ $t('FORM.STATUS.SAVE_RECEIPT') }}
          </button>
          <button v-if="this.formStatus === 'FAILED' && this.showModal"
                  class="button-pay button-accent brand-button-accent brand-button-border-radius"
                  type="button" @click="showModalWindow()">
            {{ $t('FORM.SUPPORT.CONTACT_BTN') }}
          </button>
          <button v-if="formData.returnUrl"
                  class="button-white brand-button brand-button-border-radius"
                  type="button" @click="navBack()">
            {{ $t('FORM.BACK_TO_SHOP') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import FormFooter from '@/components/form/pay/FormFooter.vue';
import CardIcons from '@/components/form/common/CardIcons.vue';
import FormHeader from '@/components/form/pay/FormHeader.vue';
import Modal from '@/components/common/Modal.vue';
import {MatomoUtils} from '@/matomo';

@Component({
  components: {
    FormFooter: FormFooter,
    CardIcons,
    FormHeader,
    Modal
  }
})
export default class PaymentStatus extends PayStepBase {
  loading = false;
  redirecting = false;

  statusText = '';
  formStatus? = '';
  description = '';
  additionalInfoStatusText?: string = '';

  showModal = false;
  isModalForSupportCanBeShown = false;
  modalText?: string;
  modalHeader?: string | null;

  mounted() {
    this.formStatus = this.formData.formStatus;
    switch (this.formData.formStatus) {
      case 'PROCESSED':
        this.statusText = 'FORM.STATUS.PROCESSED';
        this.formStatus = 'PROCESSED';
        if(this.formData.paymentMethod === "YANDEX_PAY") {
          this.additionalInfoStatusText = 'FORM.STATUS.PAYED_BY_YANDEX'
        }
        break;

      case 'FAILED':
        this.statusText = 'FORM.STATUS.DECLINED';
        this.formStatus = 'FAILED';
        if ((this.formData.brandInfo.supportLink || this.formData.brandInfo.supportPhone || this.formData.brandInfo.supportEmail)
          && this.formData.formStatusDetails === 'Transaction failed') {
          this.showModal = true;
        }

        if (this.showModal) {
          this.modalHeader = '<div><b>' + String(this.$t('FORM.SUPPORT.MODAL.HEADER')) + '</b></div>';
          this.modalText = '<div>'
          this.modalText += String(this.$t('FORM.SUPPORT.MODAL.MAIN_FAILED_BODY'))  + ' ' + this.formData.token + String(this.$t('FORM.SUPPORT.MODAL.END_OF_BODY')) + '<br><br><br>';
          if (this.formData.brandInfo.supportLink) {
            this.modalText += '<a href=\"http://' + this.formData.brandInfo.supportLink + '\">' + this.formData.brandInfo.supportLink + '</a><br>';
          }
          if (this.formData.brandInfo.supportPhone) {
            this.modalText += String(this.$t('FORM.SUPPORT.MODAL.PHONE')) + ': ' + this.formData.brandInfo.supportPhone + '<br>';
          }
          if (this.formData.brandInfo.supportEmail) {
            this.modalText += String(this.$t('FORM.SUPPORT.MODAL.EMAIL')) + ': ' + '<a href = \"mailto: ' + this.formData.brandInfo.supportEmail + '\">' + this.formData.brandInfo.supportEmail + '</a></div>';
          }
        }
        break;
      case 'EXPIRED':
        this.statusText = 'FORM.STATUS.EXPIRED';
        this.formStatus = 'FAILED';
        if (this.formData.brandInfo.supportLink || this.formData.brandInfo.supportPhone || this.formData.brandInfo.supportEmail) {
          this.showModal = true;
        }
        if (this.showModal) {
          this.modalHeader = '<div><b>' + String(this.$t('FORM.SUPPORT.MODAL.HEADER')) + '</b></div>';
          this.modalText = '<div>'
          this.modalText += String(this.$t('FORM.SUPPORT.MODAL.MAIN_EXPIRED_BODY')) + ' ' + this.formData.token + String(this.$t('FORM.SUPPORT.MODAL.END_OF_BODY')) + '<br><br><br>';
          if (this.formData.brandInfo.supportLink) {
            this.modalText += '<a href=\"http://' + this.formData.brandInfo.supportLink + '\">' + this.formData.brandInfo.supportLink + '</a><br>';
          }
          if (this.formData.brandInfo.supportPhone) {
            this.modalText += String(this.$t('FORM.SUPPORT.MODAL.PHONE')) + ': ' + this.formData.brandInfo.supportPhone + '<br>';
          }
          if (this.formData.brandInfo.supportEmail) {
            this.modalText += String(this.$t('FORM.SUPPORT.MODAL.EMAIL')) + ': ' + '<a href = \"mailto: ' + this.formData.brandInfo.supportEmail + '\">' + this.formData.brandInfo.supportEmail + '</a></div>';
          }
        }
        break;
      case 'INCOMPLETE':
      default:
        this.statusText = 'FORM.STATUS.PROCESSING';
        this.formStatus = 'PENDING';
        break;
    }
  }

  showModalWindow(): void {
    this.isModalForSupportCanBeShown = true;
  }

  closeModalWindow(): void {
    this.isModalForSupportCanBeShown = false;
  }

  navBack() {
    MatomoUtils.sendRedirectInteraction('Redirect to returnUrl', 'final status page');
    document.location.href = this.formData.returnUrl as unknown as string;
  }
}
</script>

<style lang="scss" scoped>

.status-label {
  margin-top: 42px;
  text-align: center;
  font-family: Montserrat-Bold, sans-serif;
  font-size: 28px;
}

.status-additional {
  font-size: 20px;
  opacity: 0.8;
}

.status-bill {
  height: 165px;
  margin: 10px 42px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  justify-content: space-around;
}
</style>
