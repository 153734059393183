<template>
  <div class="form-root">
    <spinner v-if="loading"></spinner>
    <div v-if="!loading">
      <div class="main-info brand-iframe">
        <div
          class="main-info-body no-select brand-secondary brand-header-border-radius-bb brand-header-border-radius-bt">
          <div class="main-info-body-label">
            <div class="payout-label">
              <span class="order-text">{{ $t('QR_FORM.LABEL') }}:</span>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-body" v-if="redirectUrl">
        <qrcode-vue :size="400" :value="this.redirectUrl" class="qrcode" level="L"/>
        <div class="panel-footer">
          <div>
            <div class="panel-footer-submit no-select">
              <button v-if="isButton"
                      class="button-pay  brand-button brand-button-border-radius" type="button"
                      v-on:click="followRedirect()">
                {{ $t('QR_FORM.FOLLOW_REDIRECT') }}
              </button>
              <button v-if="!isButton"
                class="button-pay brand-button brand-button-border-radius" type="button"

                v-on:click="copyText()">
                {{$t('QR_FORM.COPY_TO_CLIPBOARD')}}
              </button>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FormBrandSrv} from '@/services/FormBrandSrv';
import {buildDemoFormBrand} from '@/utils/test';
import {getLocalUrlBase} from '@/utils/urls';
import QrcodeVue from 'qrcode.vue';
import Spinner from '@/components/common/Spinner.vue';
import {isMobile} from '@/utils/utils';
import {BillingFormApiService} from "@/services/api/billing/form-billing.api.service";
import DataResponse = com.paidora.framework.common.web.models.responses.DataResponse;
import FormRawQrLinkResponse = com.paidora.billing.app.form.responses.FormRawQrLinkResponse;

@Component({
  components: {
    Spinner,
    QrcodeVue
  }
})
export default class QrPaymentForm extends Vue {
  loading = true;
  redirectUrl!: string;
  isButton = false;

  async mounted() {
    FormBrandSrv.applyBrand(buildDemoFormBrand({}));
    var token = this.$route.params.token as string;
    if (!token) {
      this.setError('ERROR.TOKEN');
      return;
    }

    if (this.$route.query.load_additional_params) {
      new BillingFormApiService().loadRawQrLink(token)
        .then((r: DataResponse<FormRawQrLinkResponse>) => {
          if (r.success) {
            this.redirectUrl = r.data.qrUrl;
            this.isButton = this.redirectUrl.startsWith('http') || this.redirectUrl.startsWith('//')
            if (isMobile()) {
              this.followRedirect();
            } else {
              this.loading = false;
            }
          } else {
            this.setError(r.error);
          }
        }, () => this.setError('ERROR.TOKEN'));
    } else {
      if (token === 'test') {
        this.loadTestData();
      } else {
        this.redirectUrl = window.location.protocol + getLocalUrlBase() + 'redirect/' + token;
      }
      if (isMobile()) {
        this.followRedirect();
      } else {
        this.loading = false;
      }
    }
  }

  setError(error?: string) {
    this.loading = false;

    if (!error) {
      error = String(this.$t('ERROR.COMMON'));
    }
    this.$router.push({path: '/error', params: {text: error}});
  }

  private followRedirect() {
    document.location.href = this.redirectUrl as string;
  }

  private copyText() {
    navigator.clipboard.writeText(this.redirectUrl);
  }

  private loadTestData() {
    this.redirectUrl = 'https://example.com/';
    //this.redirectUrl = '00020101021226990014br.gov.bcb.pix2577pix.bancogenial.com/qrs1/v2/01MDEby9D5YaOUYZWcr3fNmAcsG5pOGnrqa6NHpt5tgezMuen520400005303986540520.005802BR5920Just%20Pagamentos%20Ltda6014Rio%20de%20Janeiro62070503***63044F95';
  }
}
</script>

<style lang="scss" scoped>

.payouts-destination-header {
  font-family: Montserrat-Extra-Bold, sans-serif;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 45px;
}
</style>
