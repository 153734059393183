<template>
  <div class="card-form">
    <div class="card-form__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class FormContent extends Vue {
}
</script>

<style scoped>
</style>
