import hexToHsl from '@/utils/color-transformer-ui/hex-to-hsl';

export default (hex: string, diff = 180, skoef = 1): string => {

  const hsl = hexToHsl(hex);

  let h = hsl.h;
  let s = hsl.s / 100;
  const l = hsl.l / 100;
  let r, g, b;

  // Shift hue to opposite side of wheel and convert to [0-1] value
  h += diff;
  if (h > 360) {
    h -= 360;
  }
  h /= 360;

  s *= skoef;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  r = Math.round(r * 255);
  g = Math.round(g * 255);
  b = Math.round(b * 255);

  // Convert r b and g values to hex
  const rgb = b | (g << 8) | (r << 16);
  return '#' + (0x1000000 | rgb).toString(16).substring(1);
}
