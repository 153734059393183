<template>
  <div class="form-root">
    <spinner v-if="loading"></spinner>
  </div>
</template>
<style>

</style>


<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Spinner from '@/components/common/Spinner.vue';
import {BillingFormApiService} from '@/services/api/billing/form-billing.api.service';
import {MatomoUtils} from '@/matomo';
import FormLinkRequest = com.paidora.billing.app.form.requests.FormLinkRequest;
import DataResponse = com.paidora.framework.common.web.models.responses.DataResponse;
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;

@Component({
  components: {
    Spinner
  }
})
export default class FormRoot extends Vue {
  loading = true;

  mounted(): void {
    let query = this.$route.query;
    MatomoUtils.sendOpenInteraction(query.type as string || 'empty', query.token as string || 'empty');
    switch (query.type) {
      case 'link':
        if (!query.token) {
          this.setError('ERROR.TOKEN');
        }

        var linkToken = String(query.token);
        const linkRequest: Partial<FormLinkRequest> = {
          token: linkToken,
          referer: document.referrer,
          amount: (this.$route.query.amount ? Number(this.$route.query.amount) : null) as number,
          currency: (this.$route.query.currency ? this.$route.query.currency : null) as string,
          returnUrl: (this.$route.query.currency ? this.$route.query.returnUrl as string : null) as string,
          description: (this.$route.query.currency ? this.$route.query.description as string : null) as string,
          orderId: (this.$route.query.currency ? this.$route.query.orderId as string : null) as string
        };

        new BillingFormApiService().addLink(linkRequest as FormLinkRequest)
          .then((r: DataResponse<PaymentFormInfo>) => {
            if (r.success) {
              this.loading = false;
              this.$router.push({path: '/pay/' + r.data.token + '/'});
            } else {
              this.setError(r.error);
            }
          }, () => this.setError('ERROR.TOKEN'));
        break;
      case 'token':
      case 'return':
        let token = query.formToken || query.token;
        if (!token) {
          this.setError('ERROR.TOKEN');
        }
        this.$router.push({path: '/pay/' + token + '/'});
        break;
      case 'payout-destination':
        if (!query.token) {
          this.setError('ERROR.TOKEN');
        }
        this.$router.push({path: '/payout-destination/' + query.token + '/'});
        break;
      case 'qr':
        if (!query.token) {
          this.setError('ERROR.TOKEN');
        } else {
          if (query.load_additional_data === 'true') {
            this.$router.push({path: '/qr/' + query.token + '/', query: {'load_additional_params': 'true'}});
          } else {
            this.$router.push({path: '/qr/' + query.token + '/'});
          }
        }

        break;
      default:
        this.loading = true;
        this.setError(String(this.$t('ERROR.ROUTE_NOT_FOUND')));
    }
  }

  setError(error?: string) {
    this.loading = false;

    if (!error) {
      error = String(this.$t('ERROR.COMMON'));
    }
    this.$router.push({path: '/error', params: {text: error}});
  }

}
</script>
