<template>
  <div class="card-form__col">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class FormCol extends Vue {
}
</script>

<style scoped>

</style>
