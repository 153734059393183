/* eslint-disable prefer-rest-params */// Generated using typescript-api-generator
import {
  WebApiMapping,
  RequestMapping,
  RequestMethod,
  PathVariable,
  RequestBody,
  RequestParam,
  ResponseBody,
  FormRequest,
  NoAuth,
  Blob,
  NoLoadBroadcasting,
  RequestFormData,
  WebApiServiceBase
} from '@/utils//api';
import {getLocalUrlBase} from '@/utils/urls';

export class PayoutsFormApiService extends WebApiServiceBase {
  constructor() {
    super(getLocalUrlBase(), 'api/payouts/form');
  }

  @RequestMapping('destination/{token}/get', RequestMethod.Post)
  @NoAuth
  @ResponseBody
  public form(@PathVariable('token') token: string, @RequestBody request: com.paidora.payouts.app.form.requests.FormGetRequest): Promise<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.payouts.app.core.models.DestinationFormData>> {
    return this.makeRequest<com.paidora.framework.common.web.models.responses.DataResponse<com.paidora.payouts.app.core.models.DestinationFormData>>(arguments);
  }

  @RequestMapping('/ping', RequestMethod.Get)
  @NoAuth
  public ping(): Promise<string> {
    return this.makeRequest<string>(arguments);
  }

  @RequestMapping('destination/{token}/submit', RequestMethod.Post)
  @NoAuth
  @ResponseBody
  public submit(@PathVariable('token') token: string, @RequestBody request: com.paidora.payouts.app.form.requests.DestinationFormSubmitRequest): Promise<com.paidora.framework.common.web.models.responses.ApiResponse> {
    return this.makeRequest<com.paidora.framework.common.web.models.responses.ApiResponse>(arguments);
  }
}
