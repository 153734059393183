<template>
  <div id="yandexpay_button_container"></div>
</template>

<script lang="ts">
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;
import {loadScript} from "vue-plugin-load-script";
import YandexPayPaymentData = com.paidora.yandexpay.model.YandexPayPaymentData;
import YandexPayMerchant = com.paidora.yandexpay.model.YandexPayMerchant;
import YandexPayOrder = com.paidora.yandexpay.model.YandexPayOrder;
import YandexPayPaymentMethod = com.paidora.yandexpay.model.YandexPayPaymentMethod;
import {hexToHsl} from "@/utils/color-transformer-ui";
import {FormBrandSrv} from "@/services/FormBrandSrv";

@Component({})
export default class YandexPay extends Vue {
  @Prop()
  formData!: PaymentFormInfo;
  @Prop()
  height!: string;

  constructor() {
    super();
    loadScript('https://pay.yandex.ru/sdk/v1/pay.js').then(() => {
      const yaPaymentData = this.getYandexPaymentDataFromPaymentInfo();
      this.mountYandexPayButton(yaPaymentData);

    }).catch(() => {
      console.log('YandexPay script is not loaded');
    })
  }

  getYandexPaymentDataFromPaymentInfo(): YandexPayPaymentData {
    let yandexPayPaymentData = <YandexPayPaymentData>{};
    yandexPayPaymentData.env = this.formData.yandexPayFormInfo.env;
    yandexPayPaymentData.version = 2;
    //@ts-ignore
    yandexPayPaymentData.countryCode = YaPay.CountryCode.Ru;
    yandexPayPaymentData.currencyCode = this.formData.currency;
    yandexPayPaymentData.merchant = <YandexPayMerchant>{
      name: this.formData.yandexPayFormInfo.merchantName,
      id: this.formData.yandexPayFormInfo.merchantId,
      url: this.formData.yandexPayFormInfo.merchantUrl
    };

    yandexPayPaymentData.order = <YandexPayOrder>{
      id: this.formData.orderId,
      total: {
        amount: String(this.formData.amount)
      },
      items: [{
        label: this.formData.description,
        amount: String(this.formData.amount)
      }
      ]
    };

    yandexPayPaymentData.paymentMethods = [<YandexPayPaymentMethod>{
      type: 'CARD',
      gateway: this.formData.yandexPayFormInfo.gateway, // 'test-gateway'
      gatewayMerchantId: this.formData.yandexPayFormInfo.gatewayMerchantId, //'test-gateway-merchant-id'
      allowedAuthMethods: ['PAN_ONLY'],
      allowedCardNetworks: [
        'VISA',
        'MASTERCARD',
        'MIR',
        'MAESTRO',
        'VISAELECTRON',
        'AMEX',
        'DISCOVER',
        'JCB',
        'UNIONPAY',
        'UZCARD'
      ]
    }]
    return yandexPayPaymentData;
  }


  onPaymentProcess(event: any) {
    const token = event.token;
    console.log(`received token ${token}`);
    this.$emit('onPaymentTokenReceived', token, 'YANDEX_PAY')
    // дальше отправлять токен на бекенд
  }

  onPaymentError(event: any) {
    console.log(`received event`);
    console.log(event);
    // что делаем если получение токена произошло с ошибкой
    //todo: кидаем метрику что что-то пошло не так
  }

  onPaymentAbort(event: any) {
    console.log(`received event`);
    console.log(event)
    // что делаем если пользователь закрыл страницу ЯPay
    // todo: кидаем метрику что пользователь ушел с оплаты япея
  }

  private mountYandexPayButton(paymentData: YandexPayPaymentData) {
    //@ts-ignore
    console.log(window.YaPay)
    //@ts-ignore
    if (window.YaPay) {
      //@ts-ignore
      window.YaPay.createSession(paymentData, {
        onProcess: this.onPaymentProcess,
        onError: this.onPaymentError,
        onAbort: this.onPaymentAbort
      }).then((ps: any) => {
        console.log(`paymentSession: `);
        console.log(ps)

        let themeOfButton: string;
        let color = FormBrandSrv.getBrand().styles.colorPrimary;
        hexToHsl(color).l >= 50 ? themeOfButton = 'BLACK' : themeOfButton = 'YELLOW';

        ps.mountButton(document.querySelector('#yandexpay_button_container'), {
          //@ts-ignore
          type: YaPay.ButtonType.Pay,
          //@ts-ignore
          theme: themeOfButton,
          //@ts-ignore
          width: YaPay.ButtonWidth.Max,
          style: {
            borderRadius: FormBrandSrv.getBrand().styles.borderRadiusButton+'px',
            height: this.height
          }
        })
      }).catch((err: any) => {
        console.log(`errorOfPaymentSessionCreation ${err}`);
        // что делаем если все таки показать кнопку не получается
      })
    }
  }
}
</script>

<style scoped>

</style>
