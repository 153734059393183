import Vue from 'vue';

export class MatomoUtils extends Vue {

  private static transactionId?: string;

  public static setTransactionID(transactionId?: string) {
    MatomoUtils.transactionId = transactionId;
  }

  private static sendMetric(eventCategory: string, eventAction: string, eventData: string): void {
    //@ts-ignore
    if (window._paq) {
      if (MatomoUtils.transactionId) {
        //@ts-ignore
        window._paq.push(['setUserId', MatomoUtils.transactionId]);
      }
      //@ts-ignore
      window._paq.push(['trackEvent', eventCategory, eventAction, eventData]);
    }
  }

  public static sendClickButtonInteraction(action: string, data: string): void {
    this.sendMetric('ClickButton', action, data);
  }

  public static sendFocusInputInteraction(action: string, data: string): void {
    this.sendMetric('FocusElement', action, data);
  }

  public static sendErrorInteraction(action: string, data: string): void {
    this.sendMetric('Error', action, data);
  }

  public static sendFormStatusChangedInteraction(action: string, data: string): void {
    this.sendMetric('StatusChanged', action, data);
  }

  public static sendFormNewStatusInteraction(action: string, data: string): void {
    this.sendMetric('NewStatus', action, data);
  }

  public static sendRedirectInteraction(action: string, data: string): void {
    this.sendMetric('Redirect', action, data);
  }

  static sendOpenInteraction(action:string , data: string) {
    this.sendMetric('Open', action, data);
  }

  static sendFormLoadingFailedInteraction(action: string, data: string) {
    this.sendMetric('Loading', action, data);
  }
}
