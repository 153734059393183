import { render, staticRenderFns } from "./YandexPay.vue?vue&type=template&id=749b97de&scoped=true"
import script from "./YandexPay.vue?vue&type=script&lang=ts"
export * from "./YandexPay.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749b97de",
  null
  
)

export default component.exports