<template>
  <div class="card-input__exp card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}"
       @click="focusExpirationField">
    <input
      id="cardMonth"
      ref="cardMonth"
      v-number-only
      :placeholder="'MM'"
      :value="inputValue.month"
      autocomplete="cc-exp-month"
      class="brand-secondary"
      data-card-field
      inputmode="numeric"
      maxlength="2"
      name="cardMonth"
      pattern="0?[1-9]|1[012]"
      required
      type="text"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changeMonth($event.target.value)"
      @click.stop
    />
    <span>/</span>
    <input
      id="cardYear"
      ref="cardYear"
      v-number-only
      :placeholder="'YY'"
      :value="inputValue.year"
      autocomplete="cc-exp-year"
      class="brand-secondary"
      data-card-field
      inputmode="numeric"
      maxlength="2"
      name="cardYear"
      pattern="[0-9]{2}"
      required
      type="text"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changeYear($event.target.value)"
      @click.stop
    />
    <div></div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import {numberOnlyDirective} from '@/components/form/common/form/directives';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';

@Component({
  directives: {
    'number-only': numberOnlyDirective
  }
})
export default class CardExpirationInputFormField extends InputFormFieldBase<{ year: string, month: string }> {
  minCardYear = 2020 - 2000;

  constructor() {
    super();
    this.inputValue = {month: '', year: ''};
  }

  get minCardMonth() {
    if (Number(this.inputValue.year) == this.minCardYear) {
      let month = new Date().getMonth() + 1;
      return (month < 10 ? '0' : '') + month;
    } else {
      return '01';
    }
  }

  focusExpirationField() {
    (this.$refs.cardMonth as any).focus();
  }

  changeMonth(value: string) {
    this.touched = true;
    if (value.length == 1) {
      if ((Number(value) != 0 && Number(value) != 1)) {
        value = '0' + value;
      }
    } else {
      if (Number(value) > 12) {
        value = '1';
        (this.$refs.cardMonth as any)?.setAttribute('value', '1');
      }
    }

    this.inputValue.month = value;
    this.validate();
    if (!this.isMonthError()) {
      (this.$refs.cardYear as any)?.focus();
    }
    this.changed({value: this.inputValue, invalid: true, canFocusNextInput: false});
  }

  changeYear(value: string) {
    if (value === '') {
      let el = this.$refs.cardMonth as HTMLInputElement;
      el?.focus();
      el?.setSelectionRange(el?.value.length, el?.value.length);
    }
    this.inputValue.year = value;
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: true,
      canFocusNextInput: !this.isYearError()
    });
  }

  isInvalid(): boolean {
    return this.isYearError() || this.isMonthError();
  }

  getErrorMessage(): string {
    return 'ERROR.CARD_EXP';
  }

  private isYearError() {
    return !this.inputValue.year || Number(this.inputValue.year) < Number(this.minCardYear);
  }

  private isMonthError() {
    return !this.inputValue.month || this.inputValue.month.length != 2 || Number(this.inputValue.month) < Number(this.minCardMonth) || Number(this.inputValue.month) > 12;
  }
}
</script>

<style scoped>

</style>
