export enum RequestMethod {
  Get = 0,
  Post = 1,
  Put = 2,
  Delete = 3,
  Options = 4,
  Head = 5,
  Patch = 6
}

export enum ContainerType {
  PathVariable = 0,
  RequestParam = 1,
  RequestBody = 2,
  FormData = 3
}

export enum ResponceType {
  Json = 0,
  Blob = 1
}

export class ParameterContainer {
  type?: ContainerType;
  value: any;
}

export enum RequestType {
  Json = 0,
  Form = 1
}

export class PathVariableContainer extends ParameterContainer {
  constructor(public value: any, public name: string) {
    super();
    this.type = ContainerType.PathVariable;
  }
}

export class RequestParamContainer extends ParameterContainer {
  constructor(public value: any, public name: string, public required: boolean) {
    super();
    this.type = ContainerType.RequestParam;
  }

}

export class RequestBodyContainer extends ParameterContainer {
  constructor(public value: any) {
    super();
    this.type = ContainerType.RequestBody;
  }
}

export class FormDataContainer extends ParameterContainer {
  constructor(public value: any) {
    super();
    this.type = ContainerType.FormData;
  }
}
