<template>
  <FormContent>
    <label>
      <b>{{ $t('FORM.DESCRIPTION') }}</b>
    </label>
    <div v-if="formData.methodDestinationInfo.reference">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <input
                :value="formData.methodDestinationInfo.reference"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.methodDestinationInfo.reference)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <div v-if="formData.methodDestinationInfo.entity">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <input
                :value="formData.methodDestinationInfo.entity"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.methodDestinationInfo.entity)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <FormRow>
      <FormCol>
        <FormField>
        </FormField>
      </FormCol>
    </FormRow>
    <div v-if="formData.amount">
      <FormRow>
        <FormCol>
          <label>
            {{ $t('FORM.AMOUNT_DESCRIPTION.WARNING_MESSAGE') }}
            <img :src="require(`@/assets/svg/exclamation_mark.svg`)"/>
          </label>
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <div class="main-info-body"
                   readonly
                   v-html="formData.amount"
              />
              <div class="c-info-icon" @click="copy(amountForCopy)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <label>
      {{ $t('FORM.INFO_RETURN_MESSAGE') }}
    </label>
  </FormContent>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import DataFormBase from "@/components/form/pay/steps/forms/DataFormBase";
import {IForm} from "@/components/form/pay/steps/forms/IForm";
import TextInputFormField from "@/components/form/common/form/fields/TextInputFormField.vue";
import FormField from "@/components/form/common/form/FormField.vue";
import FormContent from "@/components/form/common/form/layout/FormContent.vue";
import FormRow from "@/components/form/common/form/layout/FormRow.vue";
import FormCol from "@/components/form/common/form/layout/FormCol.vue";
import Modal from "@/components/common/Modal.vue";

@Component(
  {
    components: {
      TextInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })

export default class MultibancoDestinationData extends DataFormBase implements IForm {
  constructor() {
    super();
  }

  copy(item: any) {
    window.navigator.clipboard.writeText(item);
  }

  get amountForCopy(): string {
    return Intl.NumberFormat(this.$i18n.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.amount).replace(',', '');
  }

}
</script>
<style lang="scss" scoped>
</style>
