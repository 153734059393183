<template>
  <FormContent>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="firstName"
            :inputName="'firstName'"
            :label="'FORM.DATA.FIRST_NAME'"
            :regexp="/^[а-яА-Яa-zA-Z\s.\-']+$/"
            :value="formFields.firstName"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="lastName"
            :inputName="'lastName'"
            :label="'FORM.DATA.LAST_NAME'"
            :regexp="/^[а-яА-Яa-zA-Z\s.\-']+$/"
            :value="formFields.lastName"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <CountryCodeInputFormField
            ref="countryCode"
            :value="formFields.countryCode"
            :defaultCountryCode="formData.countryIsoCode"
            @changed="fieldChanged()"
          ></CountryCodeInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="address"
            :inputName="'address'"
            :label="'FORM.DATA.ADDRESS'"
            :value="formFields.address"
            :min="5"
            :max="255"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="city"
            :inputName="'city'"
            :label="'FORM.DATA.CITY'"
            :value="formFields.city"
            :min="2"
            :max="255"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="zip"
            :inputName="'zip'"
            :label="'FORM.DATA.ZIP_CODE'"
            :value="formFields.zip"
            :regexp="/^[а-яА-Яa-zA-Z0-9\s.\-']+$/"
            :min="2"
            :max="255"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <MobilePhoneInputFormField
            ref="phone"
            :countryIsoCode="formData.countryIsoCode"
            :inputName="'phone'"
            :value="formFields.phone"
            @changed="fieldChanged()"
          ></MobilePhoneInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
    <FormRow>
      <FormCol>
        <FormField>
          <TextInputFormField
            ref="email"
            :inputName="'email'"
            :label="'FORM.DATA.EMAIL'"
            :value="formFields.email"
            :regexp="emailRegexp"
            :min="2"
            :max="255"
            @changed="fieldChanged()"
          ></TextInputFormField>
        </FormField>
      </FormCol>
    </FormRow>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import TextInputFormField from '@/components/form/common/form/fields/TextInputFormField.vue';
import MobilePhoneInputFormField from '@/components/form/common/form/fields/MobilePhoneInputFormField.vue';
import CountryCodeInputFormField from '@/components/form/common/form/fields/CountryCodeInputFormField.vue';
import {emailRegexp} from '@/utils/utils';

@Component(
  {
    components: {
      TextInputFormField,
      MobilePhoneInputFormField,
      CountryCodeInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })
export default class CustomerDataForm extends DataFormBase implements IForm {
  emailRegexp = emailRegexp;

  constructor() {
    super();
    this.formFields = {
      firstName: '',
      lastName: '',
      countryCode: '',
      address: '',
      city: '',
      zip: '',
      phone: '',
      email: ''
    };
  }

  fieldChanged() {
    this.validate();
  }
}
</script>

<style lang="scss" scoped>
</style>
